<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">USER FLASH DATES</p>
      <div class="d-flex">
        <InputSearch v-model="dataSearch"></InputSearch>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <Datatable
        :headers="headers"
        :dataColumn="dataUserFlashDate"
        :itemKey="itemKey"
        :totalPages="totalPages"
        :currentPage="currentPage"
        @sendCurrentPage="getCurrentPage"
      >
      </Datatable>
    </div>
  </div>
</template>
<script>
import Datatable from '@/components/datatable.vue';
import InputSearch from '@/components/inputSearch.vue';
import FlashDateService from '@/services/flashdate.service.js';
import { formatTime, capitalizeFirstLetter } from '@/utils/helper.js';
export default {
  name: 'Users',
  data() {
    return {
      dataSearch: '',
      headers: [
        { text: 'FLASH DATE', value: 'flashDate' },
        { text: 'USER', value: 'user' },
        { text: 'STATUS', value: 'status' },
        { text: 'DATE & TIMES', value: 'chooseTime' },
      ],
      itemKey: 'id',
      dataUserFlashDate: [],
      status: '',
      currentPage: 1,
      currentLimit: 10,
      totalPages: 0,
    };
  },
  components: {
    Datatable,
    InputSearch,
  },
  methods: {
    formatTime,
    capitalizeFirstLetter,
    getListUserFlashDate(page, limit, search, status) {
      const params = {
        page,
        limit,
        search,
        status,
      };
      FlashDateService.getListUserFlashDate(params).then((res) => {
        this.dataUserFlashDate = [];
        this.totalPages = Math.ceil(res.count / this.currentLimit);
        res.results.map((el) => {
          this.dataUserFlashDate.push({
            flashDate: el.flash_date,
            user: el.user.name,
            user_id: el.user.user_id,
            is_removed: el.user.is_removed,
            status: this.capitalizeFirstLetter(el.status),
            chooseTime: new Date(el.choose_time * 1000),
            id: el.id,
          });
        });
      });
    },
    getCurrentPage(value) {
      this.currentPage = value;
      this.getListUserFlashDate(
        value,
        this.currentLimit,
        this.dataSearch,
        this.status
      );
    },
  },
  mounted() {
    this.getListUserFlashDate(
      this.currentPage,
      this.currentLimit,
      this.dataSearch,
      this.status
    );
  },
  watch: {
    dataSearch(value) {
      this.currentPage = 1;
      this.getListUserFlashDate(
        this.currentPage,
        this.currentLimit,
        value,
        this.incomeStatus
      );
    },
  },
};
</script>
