export default {
  namespaced: true,
  state: {
    role: '',
  },

  // actions
  actions: {
    // login({ commit, dispatch }, data) {},
  },

  // mutations

  mutations: {
    setCurrentRole(state, data) {
      state.role = data;
    },
  },

  //getters
  // getNewUser(state) {
  //   return state;
  // },
};
