<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">FAQs</p>
      <div class="d-flex">
        <InputSearch v-model="dataSearch"></InputSearch>
      </div>
    </div>
    <div class="text-right w-df">
      <button
        type="button"
        class="btn btn-success text-white mt-3"
        @click="createFAQ()"
      >
        Add FAQ
        <CIcon class="pointer text-white" name="cil-plus" />
      </button>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <Datatable
        :headers="headers"
        :dataColumn="dataColumn"
        :totalPages="totalPages"
        :currentPage="currentPage"
        @sendCurrentPage="getCurrentPage"
        @deleteAction="deleteAction"
        @updateAction="updateAction"
      >
      </Datatable>
    </div>
    <confirmModal
      ref="confirm-delete"
      :title="`Confirm Delete`"
      :content="`Are you sure you want to delete FAQ?`"
      @handleCloseModal="handleCloseModal"
    />
  </div>
</template>
<script>
import Datatable from '@/components/datatable.vue';
import InputSearch from '@/components/inputSearch.vue';
import UserService from '@/services/user.service.js';
import { PATH } from '@/variables/path.js';
import { Toastmixin } from '@/mixins/toastMinxin';
export default {
  name: 'Users',
  mixins: [Toastmixin],
  data() {
    return {
      idField: 'id',
      listUsers: [],
      dataSearch: '',
      headers: [
        { text: 'Question', value: 'question', sortable: false, width: '20%' },
        { text: 'Answer', value: 'answer', sortable: false, width: '40%' },
        { text: 'Created', value: 'created', sortable: false, width: '10%' },
        { text: 'Modified', value: 'modified', sortable: false, width: '10%' },
        { text: 'Order', value: 'order', sortable: false, width: '5%' },
        { text: ' ', value: 'faqAction', sortable: false, width: '5%' },
      ],
      dataColumn: [],
      currentPage: 1,
      limit: 10,
      totalPages: 0,
      currentId: '',
    };
  },
  components: {
    Datatable,
    InputSearch,
    confirmModal: () => import('@/components/modals/confirm-modal.vue'),
  },
  methods: {
    getFAQList() {
      UserService.getFAQList(
        this.currentPage,
        this.limit,
        this.dataSearch
      ).then((rs) => {
        this.dataColumn = rs.results;
        this.totalPages = Math.ceil(rs.count / this.limit);
      });
    },
    getCurrentPage(value) {
      this.currentPage = value;
      this.getFAQList();
    },
    deleteAction(id) {
      this.currentId = id;
      this.$refs['confirm-delete'].openModal();
    },
    handleCloseModal(status) {
      if (status === 'yes') {
        UserService.deleteFAQ(this.currentId)
          .then(() => {
            this.showToast('Message', 'Delete FAQ successfully', 'success');
            this.getFAQList();
          })
          .catch((error) => {
            this.showToast(
              'Message',
              error.message ? error.message : 'Can not delete FAQ',
              'danger'
            );
          });
      }
    },
    updateAction(id) {
      this.$router.push({
        path: PATH.CREATE_FAQ,
        query: {
          id,
        },
      });
    },
    createFAQ() {
      this.$router.push({
        path: PATH.CREATE_FAQ,
      });
    },
  },
  mounted() {
    this.getFAQList();
  },
  watch: {
    dataSearch() {
      this.currentPage = 1;
      this.getFAQList();
    },
  },
};
</script>
