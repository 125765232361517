<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">REPORTED USERS</p>
      <div class="d-flex">
        <InputSearch v-model="dataSearch"></InputSearch>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <Datatable
        :totalPages="totalPages"
        :currentPage="currentPage"
        :headers="headers"
        :dataColumn="dataColumn"
        @sendCurrentPage="getCurrentPage"
      >
      </Datatable>
    </div>
  </div>
</template>
<script>
import Datatable from '@/components/datatable.vue';
import InputSearch from '@/components/inputSearch.vue';
import UserService from '@/services/user.service.js';
import { capitalizeFirstLetter } from '@/utils/helper.js';
export default {
  name: 'ReportedUser',
  data() {
    return {
      listUsers: [],
      dataSearch: '',
      headers: [
        { text: 'REPORTED BY', value: 'user_from.name' },
        { text: 'REPORTED USERS', value: 'user_to.name' },
        { text: 'REASON', value: 'reason' },
        { text: 'IMAGES', value: 'images' },
        { text: 'HISTORY CHAT', value: 'list_id' },
        { text: 'CREATED', value: 'created' },
      ],
      itemKey: 'id',
      dataColumn: [],
      currentPage: 1,
      currentLimit: 10,
      totalPages: 0,
    };
  },
  components: {
    Datatable,
    InputSearch,
  },
  methods: {
    capitalizeFirstLetter,
    getListReport(page, limit, search) {
      const params = {
        page,
        limit,
        search,
      };
      UserService.userReport(params).then((res) => {
        this.totalPages = Math.ceil(res.count / this.currentLimit);
        res.results.map((item) => {
          item.list_id = {
            user_from: item.user_from.user_id,
            user_to: item.user_to.user_id,
          };
        });
        this.dataColumn = res.results;
      });
    },
    getCurrentPage(value) {
      this.currentPage = value;
      this.getListReport(value, this.currentLimit, this.dataSearch);
    },
  },
  mounted() {
    this.getListReport(this.currentPage, this.currentLimit, this.dataSearch);
  },
  watch: {
    dataSearch(value) {
      this.currentPage = 1;
      this.getListReport(this.currentPage, this.currentLimit, value);
    },
  },
};
</script>
