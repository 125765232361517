<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">FLASH DATES</p>
      <div class="d-flex">
        <InputSearch v-model="dataSearch"></InputSearch>
      </div>
    </div>
    <div class="header-page d-flex align-items-center">
      <p class="title-text mb-0">Active:</p>
      <select
        v-model="isActive"
        class="form-select form-select-sm w-25 ml-2"
        aria-label=".form-select-sm example"
      >
        <option :value="''">All</option>
        <option :value="true">Active</option>
        <option :value="false">Inactive</option>
      </select>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <Datatable
        :headers="headers"
        :dataColumn="dataFlashDate"
        :itemKey="itemKey"
        :totalPages="totalPages"
        :currentPage="currentPage"
        @sendCurrentPage="getCurrentPage"
        @rowSelect="rowSelect"
      >
      </Datatable>
    </div>
  </div>
</template>
<script>
import Datatable from '@/components/datatable.vue';
import InputSearch from '@/components/inputSearch.vue';
import FlashDateService from '@/services/flashdate.service.js';
import { formatTime, capitalizeFirstLetter } from '@/utils/helper.js';
export default {
  name: 'Users',
  data() {
    return {
      listUsers: [],
      dataSearch: '',
      numRows: 0,
      headers: [
        { text: 'OWNER', value: 'owner' },
        { text: 'TIMES & DATES', value: 'timeDate' },
        { text: 'LENGTH OF DATE', value: 'lengthDate' },
        { text: 'AMOUNT', value: 'amount' },
        { text: 'STATUS', value: 'status' },
        { text: 'IS ACTIVE', value: 'is_active' },
        { text: 'CREATED', value: 'created' },
      ],
      itemKey: 'id',
      dataFlashDate: [],
      status: '',
      isActive: '',
      currentPage: 1,
      currentLimit: 10,
      totalPages: 0,
    };
  },
  components: {
    Datatable,
    InputSearch,
  },
  methods: {
    formatTime,
    capitalizeFirstLetter,
    rowSelect(value) {
      this.numRows = value;
    },
    getListFlashDateData(page, limit, search, status, isActive) {
      const params = {
        page,
        limit,
        search,
        status,
        isActive,
      };
      FlashDateService.getFlashDateList(params).then((res) => {
        this.dataFlashDate = [];
        this.totalPages = Math.ceil(res.count / this.currentLimit);
        res.results.map((el) => {
          this.dataFlashDate.push({
            owner: el.owner.name,
            created: el.created,
            owner_id: el.owner.user_id,
            timeDate: el.times,
            lengthDate:
              el.duration + `${el.duration > 1 ? ' hours' : ' hours'}`,
            amount: el.amount,
            status: this.capitalizeFirstLetter(el.status),
            is_active: el.is_active,
            is_removed: el.owner.is_removed,
            id: el.id,
          });
        });
      });
    },
    getCurrentPage(value) {
      this.currentPage = value;
      this.getListFlashDateData(
        value,
        this.currentLimit,
        this.dataSearch,
        this.status,
        this.isActive
      );
    },
  },
  mounted() {
    this.getListFlashDateData(
      this.currentPage,
      this.currentLimit,
      this.dataSearch,
      this.status,
      this.isActive
    );
  },
  watch: {
    dataSearch(value) {
      this.currentPage = 1;
      this.getListFlashDateData(
        this.currentPage,
        this.currentLimit,
        value,
        this.status,
        this.isActive
      );
    },
    isActive(value) {
      this.getListFlashDateData(
        this.currentPage,
        this.currentLimit,
        this.dataSearch,
        this.status,
        value
      );
    },
  },
};
</script>
