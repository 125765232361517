<template>
  <div class="user-detail">
    <div
      :id="this.$store.state.reponsive.sidebarMinimize ? 'small' : 'big'"
      class="header-page user-detail-title d-flex align-items-center justify-content-between"
    >
      <p class="title mb-0">USER DETAILS</p>
      <div class="d-flex justify-content-between">
        <button @click="openModalMessage" class="button-primary">
          SEND MESSAGE
        </button>
      </div>
    </div>

    <div class="user-detail detail">
      <div class="d-flex">
        <!-- Content view user -->
        <div class="mt-5">
          <b-card
            :id="
              this.$store.state.reponsive.sidebarMinimize
                ? 'card-left-mini'
                : 'card-left'
            "
            header="VIEW USER"
            header-bg-variant="white"
            header-tag="header"
          >
            <b-card-text class="d-flex"
              ><b-avatar
                class="mb-3"
                variant="info"
                :src="infoUser.avatar ? infoUser.avatar.url : ''"
                size="4rem"
              ></b-avatar>
              <div class="info d-flex">
                <span class="mr-auto">Username : {{ infoUser.username }}</span>
                <span class="mr-auto"
                  >Last login :
                  {{
                    infoUser.last_login | moment('ddd, MMMM Do YYYY -  h:mm A')
                  }}</span
                >
              </div>
            </b-card-text>
            <div class="row">
              <div class="title-text text-short col-3">Email:</div>
              <div class="col-6 normal-text">{{ infoUser.email }}</div>
            </div>
            <div class="row">
              <div class="title-text text-short col-3">Password:</div>
              <div class="col-6 normal-text">
                *******{{
                  infoUser.password
                    ? infoUser.password.slice(
                        infoUser.password.length - 5,
                        infoUser.password.length
                      )
                    : ''
                }}
              </div>
            </div>
            <div class="row">
              <div class="title-text text-short col-3">Balance:</div>
              <div class="col-6 normal-text">
                {{ infoUser.balance_currency }}{{ infoUser.balance }}
              </div>
            </div>
          </b-card>
        </div>

        <!-- Personal Info -->
        <div class="mt-5">
          <b-card
            :id="
              this.$store.state.reponsive.sidebarMinimize
                ? 'card-right-mini'
                : 'card-right'
            "
            header="PERSONAL INFO"
            header-bg-variant="white"
            header-tag="header"
            class="mb-0"
          >
            <b-card-text
              ><div class="personal_flex d-flex">
                <div class="w-100">
                  <div class="d-flex">
                    <div class="personal">
                      <div>
                        <div class="title-text d-flex">
                          <p class="w-45 mr-1">First Name:</p>
                          {{ infoUser.first_name }}
                        </div>
                        <div class="title-text mr-1 d-flex">
                          <p class="w-45 mr-1">Phone:</p>
                          {{ infoUser.phone }}
                        </div>
                      </div>
                    </div>
                    <div class="personal income-status">
                      <div>
                        <div class="title-text d-flex">
                          <p class="w-45 mr-1">Last Name:</p>
                          {{ infoUser.last_name }}
                        </div>
                      </div>
                      <div class="title-text d-flex">
                        <p class="w-45 mr-1">Gender:</p>
                        {{ infoUser.gender }}
                      </div>
                      <!-- <div class="d-flex">
                        <p class="title-text mr-1 w-45">Income Status:</p>
                        <b-button
                          class="button"
                          :class="{
                            'text-open': infoUser.income_status === 'OPEN',
                            'text-rejected':
                              infoUser.income_status === 'REJECTED',
                            'text-approved':
                              infoUser.income_status === 'APPROVED',
                            'text-pending':
                              infoUser.income_status === 'PENDING',
                          }"
                          v-if="!display_income"
                          >{{ infoUser.income_status }}</b-button
                        ><b-form-select
                          @change="editIncomeStatus"
                          v-if="display_income"
                          class="select"
                          v-model="selected_income"
                          :options="options"
                        ></b-form-select>
                        <img
                          v-if="
                            !display_income &&
                            infoUser.income_status === 'PENDING'
                          "
                          @click="
                            (display_income = !display_income), (check = true)
                          "
                          class="icon-edit"
                          src="../../assets/icon/edit.jpg"
                        /><img
                          @click="editStatus"
                          class="check ml-3"
                          v-if="check && display_income"
                          src="../../assets/icon/checked.png"
                          alt=""
                        />
                      </div> -->
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="personal">
                      <div class="title-text d-flex">
                        <p class="w-45 mr-1">Birthday:</p>
                        {{ infoUser.birthday }}
                      </div>
                    </div>

                    <div class="personal">
                      <div class="title-text d-flex">
                        <p class="w-45 mr-1">Objective:</p>
                        {{ infoUser.objective }}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="d-flex">
                    <div class="personal">
                      <div class="title-text d-flex">
                        <p class="w-45 mr-1">Income:</p>
                        {{ infoUser.income }}
                      </div>
                      <div class="title-text d-flex">
                        <p class="w-45 mr-1">Profession:</p>
                        {{ infoUser.profession }}
                      </div>
                    </div>

                    <div class="personal">
                      <div class="title-text d-flex">
                        <p class="w-45 mr-1">Education:</p>
                        {{ infoUser.education }}
                      </div>
                      <div class="title-text d-flex">
                        <p class="w-45 mr-1">School:</p>
                        {{ infoUser.school }}
                      </div>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="personal">
                      <div class="title-text mb-1 d-flex">
                        <p class="w-45 mr-1">Income Images:</p>
                        <div
                          class=""
                          v-if="
                            infoUser.income_images
                              ? infoUser.income_images.length === 0
                              : ''
                          "
                        >
                          No Images
                        </div>
                      </div>
                      <div class="d-flex mb-4">
                        <div
                          class="mr-1"
                          v-for="(url, index) in infoUser.income_images"
                          :key="index"
                          @click="openModalImage(infoUser.income_images, index)"
                        >
                          <img :src="url.url" class="img_user-detail mr-2" />
                        </div>
                      </div>
                    </div>

                    <div class="personal">
                      <div class="title-text mb-1 d-flex">
                        <p class="w-45 mr-1">Education Images:</p>
                        <div
                          class=""
                          v-if="
                            infoUser.diploma_images
                              ? infoUser.diploma_images.length === 0
                              : ''
                          "
                        >
                          No Images
                        </div>
                      </div>
                      <div class="d-flex mb-4">
                        <div
                          class="mr-1"
                          v-for="(url, index) in infoUser.diploma_images"
                          :key="index"
                          @click="
                            openModalImage(infoUser.diploma_images, index)
                          "
                        >
                          <img :src="url.url" class="img_user-detail mr-2" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="personal d-flex">
                      <p class="title-text mr-1 w-45">Income Status:</p>
                      <b-button
                        class="button"
                        :class="{
                          'text-open': infoUser.income_status === 'OPEN',
                          'text-rejected':
                            infoUser.income_status === 'REJECTED',
                          'text-approved':
                            infoUser.income_status === 'APPROVED',
                          'text-pending': infoUser.income_status === 'PENDING',
                        }"
                        v-if="!display_income"
                        >{{ infoUser.income_status }}</b-button
                      ><b-form-select
                        @change="editIncomeStatus"
                        v-if="display_income"
                        class="select"
                        v-model="selected_income"
                        :options="options"
                      ></b-form-select>
                      <img
                        v-if="
                          !display_income &&
                          infoUser.income_status === 'PENDING'
                        "
                        @click="
                          (display_income = !display_income), (check = true)
                        "
                        class="icon-edit"
                        src="../../assets/icon/edit.jpg"
                      /><img
                        @click="editStatus"
                        class="check ml-3"
                        v-if="check && display_income"
                        src="../../assets/icon/checked.png"
                        alt=""
                      />
                    </div>
                    <div class="personal d-flex">
                      <p class="title-text mr-1 w-45">Diploma Status:</p>
                      <button
                        class="button"
                        :class="{
                          'text-open': infoUser.diploma_status === 'OPEN',
                          'text-rejected':
                            infoUser.diploma_status === 'REJECTED',
                          'text-approved':
                            infoUser.diploma_status === 'APPROVED',
                          'text-pending': infoUser.diploma_status === 'PENDING',
                        }"
                        v-if="!display_diploma"
                      >
                        {{ infoUser.diploma_status }}
                      </button>
                      <b-form-select
                        @change="editDiplomaStatus"
                        v-if="display_diploma"
                        class="select"
                        v-model="selected_diploma"
                        :options="options"
                      ></b-form-select>
                      <img
                        v-if="
                          !display_diploma &&
                          infoUser.diploma_status === 'PENDING'
                        "
                        @click="
                          (display_diploma = !display_diploma), (check = true)
                        "
                        class="icon-edit"
                        src="../../assets/icon/edit.jpg"
                      /><img
                        @click="editStatus"
                        class="check ml-3"
                        v-if="check && display_diploma"
                        src="../../assets/icon/checked.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </div>

      <!-- Avatar -->
      <div class="mt-4 bio-avt-user">
        <b-card
          :id="
            this.$store.state.reponsive.sidebarMinimize
              ? 'card-mid-mini'
              : 'card-mid'
          "
          header="BIO & AVATAR USER"
          header-bg-variant="white"
          header-tag="header"
        >
          <b-card-text>
            <div class="mb-2">
              <div class="title-text d-flex">
                <div class="mr-1 bio-avt">Bio:</div>
                <div class="content-bio-avt" v-if="infoUser.bio">
                  {{ infoUser.bio }}
                </div>
                <div class="title-text content-bio-avt" v-else>No bio</div>
              </div>
            </div>
            <div class="d-flex">
              <div class="title-text bio-avt">Avatar User:</div>
              <div class="d-flex flex-wrap content-bio-avt">
                <div
                  class="mr-1 mb-2 mt-2"
                  v-for="(item, index) in infoUser.avatars"
                  :key="index"
                  @click="openModalImage(infoUser.avatars, index)"
                >
                  <img :src="item.url" class="img_user-avt mr-2" />
                </div>
              </div>

              <div
                class="title-text"
                v-if="infoUser.avatars ? infoUser.avatars.length === 0 : ''"
              >
                No Images
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
      <!-- Permissions -->
      <div class="mt-4">
        <b-card
          :id="
            this.$store.state.reponsive.sidebarMinimize
              ? 'card-mid-mini'
              : 'card-mid'
          "
          header="PERMISSIONS"
          header-bg-variant="white"
          header-tag="header"
        >
          <b-card-text>
            <b-row class="text-center">
              <b-col cols="6" class="left">Active</b-col>
              <b-col cols="3"
                ><img
                  v-if="infoUser.is_active"
                  class="active_icon"
                  src="../../assets/icon/active.jpg" />
                <img
                  v-if="!infoUser.is_active"
                  class="deactive_icon"
                  src="../../assets/icon/noactive.jpeg"
              /></b-col>
              <b-col cols="3" class="left"
                ><div class="hyperlink-active" @click="handleActionUser">
                  <span v-if="infoUser.is_active">Inactive </span
                  ><span v-else>Active</span>
                </div></b-col
              >
            </b-row>
            <b-row class="text-center">
              <b-col cols="6" class="left">Staff status</b-col>
              <b-col cols="3"
                ><img
                  v-if="infoUser.is_staff"
                  class="active_icon"
                  src="../../assets/icon/active.jpg" />
                <img
                  v-if="!infoUser.is_staff"
                  class="deactive_icon"
                  src="../../assets/icon/noactive.jpeg"
              /></b-col>
              <b-col cols="3" class="left"></b-col>
            </b-row>
            <b-row class="text-center">
              <b-col cols="6" class="left">Superuser status</b-col>
              <b-col cols="3"
                ><img
                  v-if="infoUser.is_superuser"
                  class="active_icon"
                  src="../../assets/icon/active.jpg" />
                <img
                  v-if="!infoUser.is_superuser"
                  class="deactive_icon"
                  src="../../assets/icon/noactive.jpeg"
              /></b-col>
              <b-col cols="3" class="left"></b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </div>

      <!-- Modal Add messagesInChannel -->
      <modalAddData
        ref="ModalAddMessages"
        :title="'Add Message'"
        @updateData="acceptAddMessage"
        :disabledButton="message && message.length < 500"
      >
        <div class="tax-modal__content__form__formgroup">
          <label for="firstname" style="width: 20%">Message:</label>
          <div
            class="tax-modal__content__form__formgroup__right d-flex align-items-center"
          >
            <textarea
              maxlength="500"
              type="text"
              id="firstname"
              v-model="message"
            />
          </div>
        </div>
      </modalAddData>

      <confirmModal
        ref="confirm-modal"
        :title="`Confirm ${action}`"
        :content="`Are you sure you want to ${action} this user?`"
        @handleCloseModal="handleCloseModal"
      />
    </div>

    <ImageModal
      :isDeleteImg="true"
      ref="img-modal"
      :imgList="imageList"
      @updatedImageList="getUserInfo()"
    />
  </div>
</template>
<script>
import UserServices from '@/services/user.service.js';
import modalAddData from '@/components/modalAddData.vue';
import ChatService from '@/services/chat.service.js';
import { PATH } from '@/variables/path.js';
import { Toastmixin } from '@/mixins/toastMinxin';
import moment from 'moment';
export default {
  name: 'UserDetail',
  mixins: [Toastmixin],
  data() {
    return {
      infoUser: {},
      image_now: '',
      index: null,
      status: null,
      selected_income: '',
      selected_diploma: '',
      options: [
        { value: 'APPROVED', text: 'Approved' },
        { value: 'REJECTED', text: 'Rejected' },
      ],
      display_income: false,
      display_diploma: false,
      check: false,
      message: '',
      currentUserId: '',
      numStatus: 0,
      action: '',
      imageList: [],
    };
  },
  created() {
    this.currentUserId = this.$route.params.user_id;
    this.getUserInfo();
  },
  components: {
    modalAddData,
    confirmModal: () => import('@/components/modals/confirm-modal.vue'),
    ImageModal: () => import('@/components/modals/show-images-modal.vue'),
  },
  methods: {
    openModalImage(imageList, index) {
      this.imageList = [];
      this.imageList.push(imageList[index]);
      imageList.map((item, idx) => {
        if (idx !== index) {
          this.imageList.push(item);
        }
      });

      this.$refs['img-modal'].openModal();
    },
    acceptNumber(number) {
      return (
        number.substring(0, 2) +
        `(${number.substring(2, 5)})` +
        number.substring(5, 8) +
        '-' +
        number.substring(8, 12)
      );
    },
    getUserInfo() {
      UserServices.userDetail(this.$route.params.user_id).then((res) => {
        res.gender = res.gender.charAt(0) + res.gender.toLowerCase().slice(1);
        res.objective = res.profile_type;
        res.birthday = moment(res.birthday).format('MM/DD/YYYY');
        res.phone = res.phone ? this.acceptNumber(res.phone) : '';
        this.infoUser = res;
        this.selected_income = res.income_status;
        this.selected_diploma = res.diploma_status;
      });
    },
    editIncomeStatus() {
      this.infoUser.income_status = this.selected_income;
    },
    editDiplomaStatus() {
      this.infoUser.diploma_status = this.selected_diploma;
    },
    editStatus() {
      let data;
      data = {
        income_status: this.selected_income,
        diploma_status: this.selected_diploma,
      };

      UserServices.editStatus(this.$route.params.user_id, data).then(() => {
        this.display_income = false;
        (this.display_diploma = false), (this.check = false);
      });
    },
    openModalMessage() {
      this.message = '';
      this.$refs.ModalAddMessages.openModal();
    },
    acceptAddMessage() {
      const data = {
        user_id: this.currentUserId,
        message: this.message,
      };
      ChatService.createNewChannel(data).then(() => {
        this.$router.push(PATH.CHATS);
      });
    },

    handleCloseModal(status) {
      if (status === 'yes') {
        UserServices.editStatus(this.$route.params.user_id, {
          is_active: !this.infoUser.is_active,
        })
          .then(() => {
            this.getUserInfo();
          })
          .catch((error) => {
            this.showToast(
              'Error',
              error && error.message ? error.message : 'Update failed',
              'danger'
            );
          });
      }
    },
    handleActionUser() {
      this.action = this.infoUser.is_active ? 'inactive' : 'activate';
      this.$refs['confirm-modal'].openModal();
    },
  },
};
</script>
