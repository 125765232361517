<template>
  <CDropdown
    inNav
    class="c-header-nav-items notification"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-bell" />
        <div class="noti-number">{{ notificationNumber }}</div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-left" color="light">
      <strong>Notifications</strong>
    </CDropdownHeader>
    <div class="scroll-noti" v-if="count > 0" @scroll="onScroll">
      <CDropdownItem
        v-for="(item, index) in notificationList"
        :key="index"
        :class="`${!item.read ? 'un-read' : 'read'} notification-item`"
        @click="clickToContent(item)"
        >{{ item.content }}
        {{ item.verb === 'ADMIN_CHAT' ? item.actor.name : '' }}
      </CDropdownItem>
    </div>
  </CDropdown>
</template>

<script>
import NotificationService from '@/services/notification.service';
import ChatService from '@/services/chat.service';
import { PATH } from '../variables/path';
export default {
  name: 'Notification',
  data() {
    return {
      itemsCount: 42,
      notificationList: [],
      notificationNumber: 0,
      page: 1,
      limit: 20,
      count: 0,
    };
  },
  methods: {
    getNotificationList(first = true) {
      if (first) {
        this.notificationList = [];
        this.page = 1;
      }
      let params = {
        q: '',
        page: this.page,
        limit: this.limit,
      };
      NotificationService.getNotificationList(params).then((rs) => {
        this.notificationList = [...this.notificationList, ...rs.results];
        this.count = rs.count;
      });
    },
    getBadge() {
      NotificationService.getBadge().then((rs) => {
        this.notificationNumber = rs;
      });
    },
    checkVerb(item) {
      if (item.verb === 'ADMIN_INCOME_EDUCATION') {
        this.$router.push({ path: PATH.VERIFY_INCOME });
      } else if (item.verb === 'ADMIN_REPORT') {
        this.$router.push({ path: PATH.REPORTED_USERS });
      } else {
        this.$router
          .push({
            path: PATH.CHATS,
            query: { channelID: item.content_object.channel_id },
          })
          .catch((err) => {
            console.log('error', err);
          });
      }
    },
    clickToContent(item) {
      if (!item.read) {
        NotificationService.markReadNoti([item.id]).then(() => {
          this.notificationList.map((noti) => {
            if (noti.id === item.id) {
              noti.read = !item.read;
            }
          });
          this.getBadge();
        });
      }
      this.checkVerb(item);
    },
    watchNotification(msg) {
      this.getBadge();
      this.notificationList.unshift(msg);
    },
    loadMore() {
      this.page += 1;
      this.getNotificationList(false);
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        scrollTop + clientHeight + this.page >= scrollHeight &&
        this.count > this.page * this.limit
      ) {
        this.loadMore();
      }
    },
  },
  mounted() {
    this.getNotificationList();
    this.getBadge();
  },
  created() {
    ChatService.onNotificationRefresh(this.watchNotification);
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
.scroll-noti {
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-noti::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.scroll-noti::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #bbb;
}
</style>
