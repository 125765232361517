<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">REASONS TO REPORT A USER</p>
      <div class="d-flex">
        <InputSearch v-model="dataSearch"></InputSearch>
      </div>
    </div>
    <div class="text-right w-df">
      <button
        type="button"
        class="btn btn-success text-white mt-3"
        @click="createReasonReport()"
      >
        Add Reason Report
        <CIcon class="pointer text-white" name="cil-plus" />
      </button>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <Datatable
        :headers="headers"
        :dataColumn="dataColumn"
        :totalPages="totalPages"
        :currentPage="currentPage"
        @sendCurrentPage="getCurrentPage"
        @deleteAction="deleteAction"
        @updateAction="updateAction"
      >
      </Datatable>
    </div>
    <confirmModal
      ref="confirm-delete"
      :title="`Confirm Delete`"
      :content="`Are you sure you want to delete Reason Report?`"
      @handleCloseModal="handleCloseModal"
    />
  </div>
</template>
<script>
import Datatable from '@/components/datatable.vue';
import InputSearch from '@/components/inputSearch.vue';
import UserService from '@/services/user.service.js';
import { PATH } from '@/variables/path.js';
import { limit } from '@/utils/helper.js';
import { Toastmixin } from '@/mixins/toastMinxin';
export default {
  name: 'Users',
  mixins: [Toastmixin],
  data() {
    return {
      dataSearch: '',
      headers: [
        { text: 'REASON', value: 'name', width: '70%' },
        { text: 'CREATED', value: 'created', width: '20%' },
        {
          text: '',
          value: 'faqAction',
          width: '10%',
        },
      ],
      dataColumn: [],
      currentPage: 1,
      totalPages: 0,
      currentId: '',
    };
  },
  components: {
    Datatable,
    InputSearch,
    confirmModal: () => import('@/components/modals/confirm-modal.vue'),
  },
  methods: {
    createReasonReport() {
      this.$router.push({
        path: PATH.CREATE_REASON_REPORT,
      });
    },
    deleteAction(id) {
      this.currentId = id;
      this.$refs['confirm-delete'].openModal();
    },
    handleCloseModal(status) {
      if (status === 'yes') {
        UserService.deleteReportReason(this.currentId)
          .then(() => {
            this.showToast(
              'Message',
              'Delete Reason Report successfully',
              'success'
            );
            this.getListReportReason(this.currentPage, limit, this.dataSearch);
          })
          .catch((error) => {
            this.showToast(
              'Error',
              error.message ? error.message : 'Can not delete Reason Report',
              'danger'
            );
          });
      }
    },
    updateAction(id) {
      this.$router.push({
        path: PATH.CREATE_REASON_REPORT,
        query: {
          id,
        },
      });
    },
    getListReportReason(page, limit, search) {
      UserService.getListReportReason(page, limit, search)
        .then((rs) => {
          if (rs.results) {
            this.dataColumn = rs.results;
            this.totalPages = Math.ceil(rs.count / limit);
          }
        })
        .catch((error) => {
          this.showToast(
            'Error',
            error.message ? error.message : 'Can not get Reason Report',
            'danger'
          );
        });
    },
    getCurrentPage(value) {
      this.currentPage = value;
      this.getListReportReason(this.currentPage, limit, this.dataSearch);
    },
  },
  mounted() {
    this.getListReportReason(this.currentPage, limit, this.dataSearch);
  },
  watch: {
    dataSearch(value) {
      this.currentPage = 1;
      this.getListReportReason(this.currentPage, limit, value);
    },
  },
};
</script>
