<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="
      (value) => $store.commit('reponsive/set', ['sidebarShow', value])
    "
  >
    <CSidebarBrand class="d-md-down-none d-flex align-items-end" to="/">
      <img class="logo_sidebar mb-2" src="../assets/icon/logo.jpg" alt="" />
      <p
        class="name-project ml-2"
        v-if="!this.$store.state.reponsive.sidebarMinimize"
      >
        WISHFUL THINKING
      </p>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="
        $store.commit('reponsive/set', ['sidebarMinimize', !minimize])
      "
    />
  </CSidebar>
</template>

<script>
import nav from './_nav';
export default {
  name: 'TheSidebar',
  nav,
  computed: {
    show() {
      return this.$store.state.reponsive.sidebarShow;
    },
    minimize() {
      return this.$store.state.reponsive.sidebarMinimize;
    },
  },
};
</script>
