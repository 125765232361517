<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">Add new Reason</p>
    </div>
    <div class="w-df pt-2">
      <div class="form-faq">
        <form>
          <div class="form-group">
            <label for="exampleFormControlInput1">Reason:</label>
            <validation-provider rules="required">
              <div slot-scope="{ errors }">
                <textarea
                  type="text"
                  rows="5"
                  class="form-control text-area-reason"
                  placeholder="Reason"
                  v-model="form.name"
                />
                <span class="error-message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
        </form>
        <div class="text-right btn-gr pt-3 ml-0">
          <button
            type="button"
            class="btn btn-success text-white"
            @click="checkAction('addNew')"
          >
            Save and add another
            <CIcon class="pointer text-white" name="cil-save" />
          </button>
          <button
            type="button"
            class="btn btn-success text-white ml-3"
            @click="checkAction('edit')"
          >
            Save and continue editing
            <CIcon class="pointer text-white" name="cil-save" />
          </button>
          <button
            type="button"
            class="btn btn-success text-white ml-3"
            @click="checkAction('onlySave')"
          >
            Save
            <CIcon class="pointer text-white" name="cil-save" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserServices from '@/services/user.service';
import { PATH } from '@/variables/path';
import { Toastmixin } from '@/mixins/toastMinxin';
import { extend } from 'vee-validate';
import { ValidationProvider } from 'vee-validate';
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    };
  },
  message: 'This field is required',
  computesRequired: true,
});
export default {
  name: 'CreateReasonReport',
  mixins: [Toastmixin],
  data() {
    return {
      form: {
        name: '',
      },
      isEdit: false,
      currentID: '',
    };
  },
  components: {
    ValidationProvider,
  },
  methods: {
    cleanFrom() {
      this.form = {
        name: '',
      };
    },
    checkAction(saveStatus) {
      if (this.isEdit) {
        this.updateReportReason(saveStatus);
      } else {
        this.createReportReason(saveStatus);
      }
    },
    checkStatus(saveStatus, rs) {
      if (saveStatus === 'addNew') {
        this.cleanFrom();
        this.isEdit = false;
      } else if (saveStatus === 'edit') {
        this.isEdit = true;
        this.currentID = rs.id;
      } else {
        setTimeout(() => {
          this.$router.push({
            path: PATH.REASON_REPORT_USER,
          });
        }, 1000);
      }
    },
    createReportReason(saveStatus) {
      UserServices.createReportReason(this.form)
        .then((rs) => {
          this.showToast(
            'Message',
            'Create Reason Report successfully',
            'success'
          );
          this.checkStatus(saveStatus, rs);
        })
        .catch((error) => {
          this.showToast(
            'Message',
            error.message
              ? this.form.name
                ? error.message
                : 'Please input reason'
              : 'Can not create Reason Report',
            'danger'
          );
        });
    },

    updateReportReason(saveStatus) {
      UserServices.updateReportReason(this.currentID, this.form)
        .then((rs) => {
          this.showToast(
            'Message',
            'Update Reason Report successfully',
            'success'
          );
          this.checkStatus(saveStatus, rs);
        })
        .catch((error) => {
          this.showToast(
            'Message',
            error.message ? error.message : 'Can not update Reason Report',
            'danger'
          );
        });
    },
    getReportReasonDetail(id) {
      UserServices.getReportReasonDetail(id)
        .then((rs) => {
          this.form.name = rs.name;
          this.currentID = rs.id;
          this.isEdit = true;
        })
        .catch((error) => {
          this.showToast(
            'Message',
            error.message ? error.message : `Can not get Reason Report ${id}`,
            'danger'
          );
        });
    },
  },
  mounted() {
    const params = this.$route.query;
    if (params.id) {
      this.getReportReasonDetail(params.id);
    }
  },
};
</script>
<style lang="scss" scoped>
form,
.btn-gr {
  max-width: 700px;
  margin: 0 auto;
}
</style>
