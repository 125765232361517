<template>
  <div class="user-page">
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">USERS</p>
      <div class="d-flex">
        <InputSearch
          v-model="dataSearch"
          style="margin-right: 20px"
        ></InputSearch>
      </div>
    </div>
    <div class="d-flex justify-content-around mt-3">
      <div class="d-flex justify-content-center">
        <!-- Wait to confirmation from client -->
        <button
          :disabled="numRows === 0"
          :class="{ disable: numRows === 0 }"
          class="button-primary"
          @click="openModalDeleteUser"
        >
          DELETE USER SELECTED
        </button>
        <button
          class="button-black ml-3"
          :disabled="numRows !== 1"
          :class="{ disable: numRows !== 1 }"
          @click="openModalMessage"
        >
          SEND MESSAGE
        </button>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <p class="mr-2 title-text mb-0">Income_Status</p>
        <b-form-select
          class="select"
          v-model="incomeStatus"
          :options="options"
        ></b-form-select>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <p class="mr-2 title-text mb-0">Diploma_Status</p>
        <b-form-select
          class="select"
          v-model="diplomaStatus"
          :options="options"
        ></b-form-select>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <Datatable
        :headers="headers"
        :dataColumn="dataUser"
        :itemKey="itemKey"
        :totalPages="totalPages"
        :currentPage="currentPage"
        :show="true"
        @sendCurrentPage="getCurrentPage"
        @rowSelect="rowSelect"
      >
      </Datatable>
    </div>

    <!-- Modal Add messagesInChannel -->
    <modalAddData
      ref="ModalAddMessages"
      :title="'Add Message'"
      @updateData="acceptAddMessage"
      :disabledButton="message && message.length < 500"
    >
      <div class="tax-modal__content__form__formgroup">
        <label for="firstname" style="width: 20%">Message:</label>
        <div
          class="tax-modal__content__form__formgroup__right d-flex align-items-center"
        >
          <textarea
            maxlength="500"
            type="text"
            id="firstname"
            v-model="message"
          />
        </div>
      </div>
    </modalAddData>

    <!-- modal delete user -->
    <!-- <ConfirmModal
      ref="modalConfirmDeleteUser"
      @accept="acceptDeleteUsers"
      :title="`Are you sure you want to delete users ?`"
    ></ConfirmModal> -->

    <!-- Toast -->
    <CAlert :color="color" :show.sync="currentAlertCounter" closeButton>
      {{ contentToast }}
      <CProgress
        :max="10"
        :value="currentAlertCounter"
        height="3px"
        :color="color"
        animate
      />
    </CAlert>
    <!-- <CButton
      color="primary"
      v-if="!currentAlertCounter"
      @click="currentAlertCounter = 10"
    >
      Show alert with timer
    </CButton> -->
    <confirmModal
      ref="confirm-modal"
      :title="`Confirm Delete`"
      :content="`Are you sure you want to delete users?`"
      @handleCloseModal="handleCloseModal"
    />
  </div>
</template>
<script>
import Datatable from '@/components/datatable.vue';
import InputSearch from '@/components/inputSearch.vue';
import UserService from '@/services/user.service.js';
import ChatService from '@/services/chat.service.js';
import { PATH } from '@/variables/path.js';
import { capitalizeFirstLetter } from '@/utils/helper.js';
import modalAddData from '@/components/modalAddData.vue';
import ConfirmModal from '@/components/confirm-modal.vue';
import { Toastmixin } from '@/mixins/toastMinxin';
export default {
  name: 'Users',
  mixins: [Toastmixin],
  data() {
    return {
      PATH,
      listUsers: [],
      dataSearch: '',
      headers: [
        { text: 'Username'.toUpperCase(), value: 'username' },
        { text: 'Email'.toUpperCase(), value: 'email' },
        { text: 'Objective'.toUpperCase(), value: 'profile_type' },
        {
          text: 'First Name'.toUpperCase(),
          value: 'first_name',
          sortable: false,
        },
        {
          text: 'Last Name'.toUpperCase(),
          value: 'last_name',
          sortable: false,
        },
        {
          text: 'Income Status'.toUpperCase(),
          value: 'income_status',
          sortable: false,
        },
        {
          text: 'Diploma Status'.toUpperCase(),
          value: 'diploma_status',
          sortable: false,
        },
        { text: 'Active'.toUpperCase(), value: 'is_active' },
      ],
      itemKey: 'user_id',
      dataUser: [],
      incomeStatus: '',
      diplomaStatus: '',
      currentPage: 1,
      currentLimit: 10,
      totalPages: 0,
      numRows: 0,
      options: [
        { value: '', text: 'All' },
        { value: 'APPROVED', text: 'Approved' },
        { value: 'OPEN', text: 'Open' },
        { value: 'REJECTED', text: 'Rejected' },
        { value: 'PENDING', text: 'Pending' },
      ],
      message: '',
      submitted: false,
      currentAlertCounter: 0,
      contentToast: '',
      color: '',
      action: 'delete',
    };
  },
  components: {
    Datatable,
    InputSearch,
    modalAddData,
    ConfirmModal,
    confirmModal: () => import('@/components/modals/confirm-modal.vue'),
  },
  methods: {
    capitalizeFirstLetter,
    getListUser(page, limit, search, incomeStatus, diplomaStatus) {
      const params = {
        page,
        limit,
        search,
        incomeStatus,
        diplomaStatus,
      };
      UserService.getUserList(params).then((res) => {
        this.totalPages = Math.ceil(res.count / this.currentLimit);
        res.results.map((result) => {
          result.income_status = this.capitalizeFirstLetter(
            result.income_status
          );
          result.diploma_status = this.capitalizeFirstLetter(
            result.diploma_status
          );
        });
        this.dataUser = res.results;
      });
    },
    getCurrentPage(value) {
      this.currentPage = value;
      this.getListUser(
        value,
        this.currentLimit,
        this.dataSearch,
        this.incomeStatus,
        this.diplomaStatus
      );
    },
    rowSelect(value) {
      this.numRows = value.length;
      this.listUserSelected = value;
    },
    openModalMessage() {
      this.message = '';
      this.$refs.ModalAddMessages.openModal();
    },
    acceptAddMessage() {
      const data = {
        user_id: this.listUserSelected[0].user_id,
        message: this.message,
      };
      ChatService.createNewChannel(data).then(() => {
        this.$router.push(PATH.CHATS);
      });
    },
    openModalDeleteUser() {
      this.$refs['confirm-modal'].openModal();
    },
    handleCloseModal(status) {
      if (status === 'yes') {
        let listUserDeleted = [];
        this.listUserSelected.map((el) => {
          listUserDeleted.push(el.user_id);
        });
        UserService.deleteUser(listUserDeleted)
          .then(() => {
            this.showToast('Success', 'Delete User Successfully', 'success');
            this.getListUser(
              this.currentPage,
              this.currentLimit,
              this.dataSearch,
              this.incomeStatus,
              this.diplomaStatus
            );
          })
          .catch((error) => {
            let name = [];
            if (error && error.errorDetail.length > 0) {
              error.errorDetail.map((item) => {
                let user = this.listUserSelected.filter(
                  (user) => user.user_id === item.id
                )[0];
                name.push(`${user.first_name} ${user.last_name}`);
              });
            }
            let message = '';
            if (name.length > 0) {
              message = `Could not take action because Flash Date is active on ${
                name.length > 1 ? name.join(', ') : name.join()
              }`;
            } else if (error && error.message) {
              message = error.message;
            } else {
              message = 'Delete user failed';
            }
            this.showToast('Error', message, 'danger');
          });
      }
    },
  },
  mounted() {
    this.getListUser(
      this.currentPage,
      this.currentLimit,
      this.dataSearch,
      this.incomeStatus,
      this.diplomaStatus
    );
  },
  watch: {
    dataSearch(value) {
      this.currentPage = 1;
      this.getListUser(
        this.currentPage,
        this.currentLimit,
        value,
        this.incomeStatus,
        this.diplomaStatus
      );
    },
    incomeStatus(value) {
      this.currentPage = 1;
      this.getListUser(
        this.currentPage,
        this.currentLimit,
        this.dataSearch,
        value,
        this.diplomaStatus
      );
    },
    diplomaStatus(value) {
      this.currentPage = 1;
      this.getListUser(
        this.currentPage,
        this.currentLimit,
        this.dataSearch,
        this.incomeStatus,
        value
      );
    },
  },
};
</script>
