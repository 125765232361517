<template>
  <div class="chat-page">
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">CHATS</p>
      <div class="d-flex"></div>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <div class="container">
        <div class="messaging">
          <div class="inbox_msg">
            <div class="inbox_people">
              <div class="headind_srch">
                <div class="srch_bar">
                  <InputSearch v-model="dataSearch"></InputSearch>
                </div>
                <div class="recent_heading mt-2">
                  <h4>Recent</h4>
                </div>
              </div>
              <div
                v-if="channels.length"
                class="scroll-channels inbox_chat"
                id="scroll-channel"
              >
                <div
                  v-for="(channel, index) in channels"
                  :key="index"
                  class="chat_list pointer"
                  :class="{
                    'unread-chat': !channel.isReaded,
                    active_chat:
                      currentChannelSelected === channel.idChannel ||
                      currentUserID === channel.userID,
                  }"
                  @click="getMessagesEachChannel(channel)"
                >
                  <div class="chat_people">
                    <div class="chat_img">
                      <img
                        v-if="channel.avatar"
                        :src="channel.avatar"
                        :alt="`sunil-${channel.idChannel}`"
                        class="border-circle"
                      />
                      <CIcon v-else class="pointer" name="cil-user" />
                    </div>
                    <div class="chat_ib">
                      <h5>
                        {{ channel.name }}
                        <span class="chat_date">{{
                          channel.lastMessageTime
                        }}</span>
                      </h5>
                      <p>
                        {{ channel.lastMessage }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="inbox_chat">
                <p class="title-text mt-3 text-center">No users found</p>
              </div>
            </div>
            <div class="mesgs">
              <div
                class="msg_history scroll-channels"
                id="list-chat"
                @scroll.passive="handleScrollMessage"
              >
                <div v-for="(message, index) in messagesInChannel" :key="index">
                  <!-- sender -->
                  <div v-if="message.isASender" class="outgoing_msg">
                    <div class="sent_msg">
                      <p>{{ message.content }}</p>
                      <span class="time_date">{{
                        message.messagesTimeSend
                      }}</span>
                    </div>
                  </div>
                  <!-- receiver -->
                  <div v-else class="incoming_msg">
                    <div class="incoming_msg_img">
                      <img
                        class="border-circle"
                        :src="message.avatar"
                        alt="sunil"
                      />
                    </div>
                    <div class="received_msg">
                      <div class="received_withd_msg">
                        <p>{{ message.content }}</p>
                        <span class="time_date">{{
                          message.messagesTimeSend
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="type_msg">
                <div class="input_msg_write position-relative">
                  <textarea
                    type="text"
                    class="write_msg"
                    placeholder="Type a message"
                    v-model="message"
                    v-on:keyup.enter="sendMessage"
                  />
                  <button
                    v-if="!isSpinner"
                    :disabled="!validateNameCreate(message)"
                    @click="sendMessage"
                    class="msg_send_btn"
                    type="button"
                  >
                    <img src="@/assets/icon/icon-send-messages.svg" />
                  </button>
                  <div
                    v-else
                    class="my-spinner spinner-message"
                    v-bind:class="{ 'spinner-active': isSpinner }"
                  >
                    <b-spinner
                      class="spinner-item"
                      label="Small Spinner"
                    ></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputSearch from '@/components/inputSearch.vue';
import ChatService from '@/services/chat.service.js';
import bus from '@/services/bus.service.js';
import * as moment from 'moment-timezone';
import { mapState } from 'vuex';
import { validateNameCreate } from '@/utils/helper.js';
export default {
  name: 'Users',
  data() {
    return {
      dataSearch: '',
      headers: [
        { text: 'Channel Name', value: 'channel_name', sortable: false },
        { text: 'Sender', value: 'sender', sortable: false },
        { text: 'Send To', value: 'sent_to', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
      ],
      itemKey: 'id',
      dataChats: [],
      channelPage: 1,
      channelLimit: 100,
      channels: [],
      messagesInChannel: [],
      currentChannelSelected: '',
      message: '',
      firstLoad: true,
      currentUserID: '',
      messagePage: 1,
      messageLimit: 50,
      totalMessage: 0,
      isSpinner: false,
      querryChannelID: '',
    };
  },
  components: {
    InputSearch,
  },
  methods: {
    handleScrollMessage(e) {
      const scrollTop = e.target.scrollTop;
      if (scrollTop === 0) {
        this.loadMoreMessage();
      }
    },
    loadMoreMessage() {
      const totalPage = Math.ceil(this.totalMessage / this.messageLimit);
      if (this.messagePage < totalPage) {
        this.messagePage++;
        const data = {
          idChannel: this.currentChannelSelected,
          page: this.messagePage,
          limit: this.messageLimit,
        };
        ChatService.getMessagesEachChannel(data).then((res) => {
          res.results &&
            res.results.length &&
            res.results.map((el) => {
              this.pushMessageIntoChanel(el);
            });
          this.sortMessages();
          let objDiv = document.getElementById('list-chat');
          if (objDiv) {
            setTimeout(() => {
              objDiv.scrollTop = 10;
            }, 0);
          }
        });
      }
    },
    validateNameCreate,
    getListChats(page, limit, search) {
      const params = {
        page,
        limit,
        search,
      };
      ChatService.getListChat(params).then((res) => {
        this.channels = [];
        this.dataChats = res.results;
        res.results &&
          res.results.length &&
          res.results.map((el) => {
            this.addUserToChannel(el);
          });
        this.scrollToTopChannel();
      });
    },
    addUserToChannel(el) {
      this.channels.push({
        avatar: el.avatar ? el.avatar.url : '',
        name: el.display_name + ` ${el.last_name}`,
        unreadMessage: el.badge,
        lastMessage: el.last_message ? el.last_message.content : '',
        isReaded: el.badge ? false : true,
        lastMessageTime: this.findLasMessagetime(el.last_message_time),
        idChannel: el.id,
      });
    },
    getMessagesEachChannel(channel) {
      if (channel.idChannel) {
        this.getMessageWithExisChannel(channel.idChannel);
        this.currentUserID = null;
      } else {
        this.selectEmptyChanel(channel.userID);
      }
    },
    selectEmptyChanel(userID) {
      this.currentChannelSelected = '';
      this.messagesInChannel = [];
      this.currentUserID = userID;
    },
    createNewChannel(userID, message) {
      const data = {
        message,
        user_id: userID,
      };
      ChatService.createNewChannel(data).then((rs) => {
        this.isSpinner = false;
        this.getMessageWithExisChannel(rs.id);
        this.getListChats();
      });
    },
    getMessageWithExisChannel(id) {
      this.message = '';
      this.totalMessage = 0;
      this.messagePage = 1;
      if (this.currentChannelSelected !== id) {
        ChatService.markReadChannel({
          channelId: id,
        });
        const index = this.channels.findIndex((el) => {
          return el.idChannel === id;
        });
        if (index !== -1) {
          this.channels[index].isReaded = true;
        }
        this.currentChannelSelected = id;
        const data = {
          idChannel: id,
          page: this.messagePage,
          limit: this.messageLimit,
        };
        ChatService.getMessagesEachChannel(data).then((res) => {
          this.messagesInChannel = [];
          this.totalMessage = res.count;
          res.results &&
            res.results.length &&
            res.results.map((el) => {
              this.pushMessageIntoChanel(el);
            });
          this.sortMessages();
          this.scrollToBottomListMessage();
        });
      }
    },
    sortMessages() {
      this.messagesInChannel.sort((a, b) => {
        let dateA = new Date(a.timeToSort);
        let dateB = new Date(b.timeToSort);
        return dateA - dateB;
      });
    },
    sendMessage() {
      if (this.validateNameCreate(this.message)) {
        this.dataSearch = '';
        this.isSpinner = true;
        if (this.currentChannelSelected) {
          this.sendMessageWithExisChannel();
        } else {
          this.createNewChannel(this.currentUserID, this.message);
        }
      }
    },
    sendMessageWithExisChannel() {
      const data = {
        message: this.message,
        channelId: this.currentChannelSelected,
      };
      ChatService.sendMessage(data).then((res) => {
        this.isSpinner = false;
        this.message = '';
        this.pushMessageIntoChanel(res);
        this.scrollToBottomListMessage();
        this.getListChats();
      });
    },
    pushMessageIntoChanel(messageObj) {
      this.messagesInChannel.push({
        content: messageObj.content,
        messagesTimeSend: this.findLasMessagetime(messageObj.created),
        id: messageObj.id,
        status: messageObj.status,
        isASender: this.userId === messageObj.sender.user_id ? true : false,
        avatar: messageObj.sender.avatar.url,
        name: messageObj.sender.name,
        timeToSort: messageObj.created,
      });
    },
    scrollToBottomListMessage() {
      let objDiv = document.getElementById('list-chat');
      setTimeout(() => {
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 0);
    },
    scrollToTopChannel() {
      let objDiv = document.getElementById('scroll-channel');
      if (objDiv) {
        setTimeout(() => {
          objDiv.scrollTop = 0;
        }, 0);
      }
    },
    findLasMessagetime(time) {
      moment.tz.setDefault('Etc/UTC');
      return moment(time).fromNow();
    },

    searchUser(page, limit, search) {
      this.channels = [];
      ChatService.searchUser(page, limit, search).then((rs) => {
        if (rs.results.length > 0) {
          this.checkIsChated(rs.results);
        }
      });
    },

    checkIsChated(userList) {
      userList.map((val) => {
        if (val.chat_channel !== null) {
          this.addUserToChannel(val.chat_channel);
        } else {
          this.addUserToChannelWithNew(val);
        }
      });
    },
    addUserToChannelWithNew(userInfo) {
      this.channels.push({
        avatar: userInfo.avatar ? userInfo.avatar.url : '',
        name: userInfo.first_name + ` ${userInfo.last_name}`,
        unreadMessage: '',
        lastMessage: '',
        isReaded: true,
        lastMessageTime: '',
        idChannel: null,
        userID: userInfo.user_id,
      });
    },
    watchMessage(msg) {
      this.freshListUser(msg);
      this.checkIsChannelActived(msg);
    },
    freshListUser(msg) {
      const index = this.channels.findIndex((el) => {
        return el.idChannel === msg.channel_id;
      });

      if (index > -1) {
        this.channels[index].isReaded = false;
        this.channels[index].lastMessage = msg.message.content;
        this.channels[index].lastMessageTime = this.findLasMessagetime(
          msg.created
        );
        const el = { ...this.channels[index] };
        this.channels.splice(index, 1);
        this.channels.unshift(el);
      }
    },
    checkIsChannelActived(msg) {
      if (msg.channel_id === this.currentChannelSelected) {
        this.pushMessageIntoChanel(msg.message);
        this.scrollToBottomListMessage();
      }
    },
    callMessageFromNotification(channelID) {
      this.getMessagesEachChannel({ idChannel: channelID });
      this.currentChannelSelected = channelID;
      const query = Object.assign({}, this.$route.query);
      delete query.channelID;
      this.$router.replace({ query });
    },
  },
  mounted() {
    this.getListChats(this.channelPage, this.channelLimit, this.dataSearch);
    this.$store.dispatch('user/getUserInfo');
  },
  created() {
    bus.$on('chatMessage', this.watchMessage);
  },
  destroyed() {
    bus.$off('chatMessage', this.watchMessage);
  },
  watch: {
    dataSearch(value) {
      this.channelPage = 1;
      if (value) {
        this.searchUser(this.channelPage, this.channelLimit, value);
      } else {
        this.getListChats(this.channelPage, this.channelLimit, this.dataSearch);
      }
    },
    channels: {
      deep: true,
      immediate: true,
      handler(value) {
        if (this.firstLoad && value.length && !this.querryChannelID) {
          this.firstLoad = false;
          this.getMessagesEachChannel(value[0]);
        }
      },
    },
    '$route.query.channelID': {
      handler(value) {
        if (value) {
          this.querryChannelID = value;
          this.callMessageFromNotification(value);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState('user', {
      userId: 'userId',
    }),
  },
};
</script>
