import chatService from './chat.service';
import { SocketIO, ApiClient } from '@goldfishcode/wishful-thinking-api-sdk';
import Vue from 'vue';
const bus = new Vue();
const socket = SocketIO.instance(process.env.VUE_APP_SOCKET_URL);
export default bus;

export const init = function () {
  ApiClient.getAuthToken().then((token) => {
    if (token) {
      socket.connect(token.access_token);
      chatService.onMessage((data) => {
        bus.$emit('chatMessage', data);
      });
    }
  });
};
