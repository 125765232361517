export const PATH = {
  HOME: '/',
  LOGIN: '/login',
  CHATS: '/chats',
  FLASH_DATES: '/flash-dates',
  USER_FLASH_DATES: '/user-flash-dates',
  ORDER_HISTORY: '/order-history',
  PRODUCTS: '/products',
  FAQS: '/faqs',
  REASON_REPORT_USER: '/reason-report-users',
  CREATE_REASON_REPORT: '/create-reason-report',
  REPORTED_USERS: '/reported-users',
  TERM_CONDITIONS: '/term-conditions',
  USERS: '/users',
  VERIFY_INCOME: '/verify-income',
  USER_DETAIL: '/user-detail',
  DASHBOARD: '/dashboard',
  CREATE_FAQ: '/create-faq',
};

export const API_PATH = {
  LOGIN: '',
  USERS: '',
};
