import axios from 'axios';
import Vue from 'vue';
import { api } from '@goldfishcode/wishful-thinking-api-sdk';

function intercepterConfig() {
  var count = 0;
  axios.interceptors.request.use(
    function (config) {
      count++;
      const offSpinner = [
        '/v1/chat/messages/',
        '/chat/messages/',
        '/v1/chat/channels/',
        '/chat/channels/',
      ];
      if (offSpinner.includes(new URL(config.url).pathname)) {
        Vue.prototype.$bus.$emit('spinner', false);
      } else {
        Vue.prototype.$bus.$emit('spinner', true);
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      count--;
      if (count === 0) {
        Vue.prototype.$bus.$emit('spinner', false);
      }
      return response;
    },
    function (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.code &&
        error.response.data.code === 8000
      ) {
        api.Auth.refreshToken()
          .then(() => {
            location.reload();
          })
          .catch((error) => {
            count--;
            if (count === 0) {
              Vue.prototype.$bus.$emit('spinner', false);
            }
            return Promise.reject(error);
          });
      } else {
        count--;
        if (count === 0) {
          Vue.prototype.$bus.$emit('spinner', false);
        }
        return Promise.reject(error);
      }
    }
  );
}

export default intercepterConfig;
