import { api } from '@goldfishcode/wishful-thinking-api-sdk';

class NotificationService {
  getNotificationList(params) {
    return api.Notification.list(params.q, params.page, params.limit);
  }

  getBadge() {
    return api.Notification.getBadge();
  }
  markReadNoti(ids) {
    return api.Notification.maskAsRead(ids);
  }
}
export default new NotificationService();
