<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">PRODUCTS</p>
      <div class="d-flex">
        <InputSearch v-model="dataSearch"></InputSearch>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3 products">
      <Datatable
        :totalPages="totalPages"
        :currentPage="currentPage"
        :headers="headers"
        :dataColumn="dataColumn"
        @sendCurrentPage="getCurrentPage"
        @updateValue="updateValue"
      >
      </Datatable>
    </div>
  </div>
</template>
<script>
import Datatable from '@/components/datatable.vue';
import InputSearch from '@/components/inputSearch.vue';
import ProductService from '@/services/product.service.js';
import { Toastmixin } from '@/mixins/toastMinxin';
import _ from 'lodash';

export default {
  name: 'Products',
  mixins: [Toastmixin],
  data() {
    return {
      dataSearch: '',
      totalPages: 0,
      headers: [
        { text: 'Product ID', value: 'product_id', width: '1%' },
        { text: 'Is Visible', value: 'is_visible', width: '1%' },
        { text: 'Name', value: 'name', width: '15%' },
        { text: 'URL', value: 'url', width: '5%' },
        { text: 'Description', value: 'description', width: '20%' },
        // { text: 'Min Cost', value: 'min_cost', width: '11%' },
        // { text: 'Max cost', value: 'max_cost', width: '11%' },
        {
          text: 'range allowed cost',
          value: 'range_allowed_cost',
          width: '35%',
        },
        { text: 'created', value: 'created', width: '30%' },
      ],
      dataColumn: [],
      currentPage: 1,
      limit: 10,
    };
  },
  components: {
    Datatable,
    InputSearch,
  },
  methods: {
    getProductList() {
      ProductService.getProductList(
        this.currentPage,
        this.limit,
        this.dataSearch
      ).then((rs) => {
        this.dataColumn = rs.results;
        this.totalPages = Math.ceil(rs.count / this.limit);
        this.prevDataColumn = _.cloneDeep(this.dataColumn);
      });
    },
    getCurrentPage(currentPage) {
      this.currentPage = currentPage;
      this.getProductList();
    },
    updateValue(item) {
      ProductService.updateProduct(item)
        .then((element) => {
          this.dataColumn = this.dataColumn.map((el) => {
            if (element.id === el.id) {
              return element;
            }

            return el;
          });

          this.prevDataColumn = _.cloneDeep(this.dataColumn);
          this.showToast('Message', 'Update product successfully!', 'success');
        })
        .catch((err) => {
          this.dataColumn = _.cloneDeep(this.prevDataColumn);
          this.showToast('Message', err.message, 'danger');
        });
    },
  },
  mounted() {
    this.getProductList();
  },
  watch: {
    dataSearch(value) {
      this.currentPage = 1;
      this.dataSearch = value;
      this.getProductList();
    },
  },
};
</script>
