<template>
  <v-edit-dialog large @save="save">
    <div class="field">
      <span v-if="rangeCost.length">
        {{ rangeCost.join(',') }}
      </span>

      <span class="range" v-else>{{ minCost }} - {{ maxCost }}</span>

      <button type="button">
        <CIcon class="c-icon-sm" name="cilPencil" />
      </button>
    </div>
    <template v-slot:input>
      <div class="range-cost">
        <div v-if="originRangeCost.length">
          <v-container>
            <v-row class="edit-record">
              <v-col :cols="originRangeCost.length >= 4 ? 3 : 4" v-for="(cost, idx) in originRangeCost" :key="idx">
                <v-checkbox
                  :label="`${cost}`"
                  color="info"
                  :value="cost"
                  v-model="rangeCost"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <div v-else>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  class="min-cost"
                  :rules="[isNumber]"
                  label="Min Cost"
                  single-line
                  v-model="minCost"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  class="max-cost"
                  :rules="[isNumber]"
                  label="Max Cost"
                  single-line
                  v-model="maxCost"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  name: 'UpdateField',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data(vm) {
    return {
      isNumber: (v) => !!Number(v) || 'Please input a valid number!',
      originRangeCost: this.sort(vm.item.original_range_cost),
      minCost: vm.item.min_cost,
      maxCost: vm.item.max_cost,
      rangeCost: this.sort(vm.item.range_cost),
    };
  },
  methods: {
    sort(arr) {
      if (arr === null) return [];

      return arr.sort((a, b) => a - b);
    },
    save() {
      const item = {
        ...this.item,
        min_cost: parseInt(this.minCost),
        max_cost: parseInt(this.maxCost),
      };

      if (this.rangeCost.length) {
        item.range_cost = this.sort(this.rangeCost);
      }

      this.$emit('save', item);
    },
  },
};
</script>
