import { PATH } from '../variables/path';
const data = [
  {
    _name: 'CSidebarNav',
    _children: [
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Dashboard',
      //   to: PATH.DASHBOARD,
      //   icon: 'cil-speedometer',
      //   isShow: true,
      // },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Chat'],
        isShow: true,
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Chats',
        to: PATH.CHATS,
        icon: 'cilCommentSquare',
        isShow: true,
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Flash date'],
        isShow: true,
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Flash Dates',
        to: PATH.FLASH_DATES,
        icon: 'cilCalendar',
        isShow: true,
      },
      {
        _name: 'CSidebarNavItem',
        name: 'User Flash Dates',
        to: PATH.USER_FLASH_DATES,
        icon: 'cilUserFemale',
        isShow: true,
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Store'],
        isShow: true,
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Products',
        to: PATH.PRODUCTS,
        icon: 'cibProductHunt',
        isShow: true,
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Order History',
        to: PATH.ORDER_HISTORY,
        icon: 'cilUserFemale',
        isShow: true,
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Users'],
        isShow: true,
      },
      {
        _name: 'CSidebarNavItem',
        name: 'FAQs',
        to: PATH.FAQS,
        icon: 'cilSettings',
        isShow: true,
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Reasons to report a user',
        to: PATH.REASON_REPORT_USER,
        icon: 'cilLayers',
        isShow: true,
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Reported Users',
        to: PATH.REPORTED_USERS,
        icon: 'cilShieldAlt',
        isShow: true,
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Term Conditions',
      //   to: PATH.TERM_CONDITIONS,
      //   icon: 'cil-pencil',
      //   isShow: true,
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Users',
        to: PATH.USERS,
        icon: 'cilUser',
        isShow: true,
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Verify Income & Educations',
        to: PATH.VERIFY_INCOME,
        icon: 'cilPeople',
        isShow: true,
      },
    ],
  },
];
data[0]._children = data[0]._children.filter((el) => el.isShow);
export default data;
