<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">ORDER HISTORY</p>
      <div class="d-flex">
        <InputSearch v-model="dataSearch"></InputSearch>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <Datatable
        :totalPages="totalPages"
        :currentPage="currentPage"
        :headers="headers"
        :dataColumn="dataColumn"
        @sendCurrentPage="getCurrentPage"
      >
      </Datatable>
    </div>
  </div>
</template>
<script>
import Datatable from '@/components/datatable.vue';
import InputSearch from '@/components/inputSearch.vue';
import ProductService from '@/services/product.service.js';
export default {
  name: 'OrderHistory',
  data() {
    return {
      dataSearch: '',
      headers: [
        { text: 'from', value: 'provider' },
        { text: 'order id', value: 'order_id' },
        { text: 'user', value: 'user.name' },
        { text: 'created', value: 'created' },
        { text: 'modified', value: 'modified' },
      ],
      dataColumn: [],
      totalPages: 0,
      currentPage: 1,
      limit: 10,
    };
  },
  components: {
    Datatable,
    InputSearch,
  },
  methods: {
    getOrderHistory() {
      ProductService.getOrderHistory(
        this.currentPage,
        this.limit,
        this.dataSearch
      ).then((rs) => {
        this.dataColumn = rs.results;
        this.totalPages = Math.ceil(rs.count / this.limit);
      });
    },
    getCurrentPage(currentPage) {
      this.currentPage = currentPage;
      this.getOrderHistory();
    },
  },
  mounted() {
    this.getOrderHistory();
  },
  watch: {
    dataSearch(value) {
      this.currentPage = 1;
      this.dataSearch = value;
      this.getOrderHistory();
    },
  },
};
</script>
