<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">Add new FAQ</p>
    </div>
    <div class="w-df pt-2">
      <div class="form-faq">
        <form>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Question</label>
            <validation-provider rules="required">
              <div slot-scope="{ errors }">
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="form.question"
                ></textarea>
                <span class="error-message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Answer</label>
            <validation-provider rules="required">
              <div slot-scope="{ errors }">
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="form.answer"
                ></textarea>
                <span class="error-message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Order</label>
            <validation-provider rules="required">
              <div slot-scope="{ errors }">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Order"
                  v-model="form.order"
                />
                <span class="error-message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
        </form>
        <div class="text-right btn-gr pt-3">
          <button
            type="button"
            class="btn btn-success text-white"
            @click="checkAction('addNew')"
          >
            Save and add another
            <CIcon class="pointer text-white" name="cil-save" />
          </button>
          <button
            type="button"
            class="btn btn-success text-white ml-3"
            @click="checkAction('edit')"
          >
            Save and continue editing
            <CIcon class="pointer text-white" name="cil-save" />
          </button>
          <button
            type="button"
            class="btn btn-success text-white ml-3"
            @click="checkAction('onlySave')"
          >
            Save
            <CIcon class="pointer text-white" name="cil-save" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserServices from '@/services/user.service';
import { extend } from 'vee-validate';
import { ValidationProvider } from 'vee-validate';
import { PATH } from '../../variables/path';
import { Toastmixin } from '@/mixins/toastMinxin';
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    };
  },
  message: 'This field is required',
  computesRequired: true,
});
export default {
  name: 'CreateFAQ',
  mixins: [Toastmixin],
  data() {
    return {
      form: {
        question: '',
        answer: '',
        order: '',
      },
      isEdit: false,
      currentID: '',
    };
  },
  components: {
    ValidationProvider,
  },
  methods: {
    cleanFrom() {
      this.form = {
        question: '',
        answer: '',
        order: '',
      };
    },
    checkAction(saveStatus) {
      if (this.isEdit) {
        this.updateFAQ(saveStatus);
      } else {
        this.createFAQ(saveStatus);
      }
    },
    checkStatus(saveStatus, rs) {
      if (saveStatus === 'addNew') {
        this.cleanFrom();
        this.isEdit = false;
      } else if (saveStatus === 'edit') {
        this.isEdit = true;
        this.currentID = rs.id;
      } else {
        setTimeout(() => {
          this.$router.push({
            path: PATH.FAQS,
          });
        }, 1000);
      }
    },
    createFAQ(saveStatus) {
      UserServices.createFAQ(this.form)
        .then((rs) => {
          this.showToast('Message', 'Create FAQ successfully', 'success');
          this.checkStatus(saveStatus, rs);
        })
        .catch((error) => {
          this.showToast('Message', error.message, 'danger');
        });
    },

    updateFAQ(saveStatus) {
      UserServices.updateFAQ(this.currentID, this.form)
        .then((rs) => {
          this.showToast('Message', 'Update FAQ successfully', 'success');
          this.checkStatus(saveStatus, rs);
        })
        .catch((error) => {
          this.showToast('Message', error.message, 'danger');
        });
    },
    getFAQDetail(id) {
      UserServices.getFAQDetail(id)
        .then((rs) => {
          this.form.question = rs.question;
          this.form.answer = rs.answer;
          this.form.order = rs.order;
          this.currentID = rs.id;
          this.isEdit = true;
        })
        .catch((error) => {
          this.showToast('Message', error.message, 'danger');
        });
    },
  },
  mounted() {
    const params = this.$route.query;
    if (params.id) {
      this.getFAQDetail(params.id);
    }
  },
};
</script>
<style lang="scss" scoped>
form,
.btn-gr {
  max-width: 700px;
  margin: 0 auto;
}
</style>
