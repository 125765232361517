<template>
  <div>
    <div class="header-page d-flex align-items-center justify-content-between">
      <p class="title mb-0">VERIFY INCOME & EDUCATION</p>
      <div class="d-flex">
        <InputSearch v-model="dataSearch"></InputSearch>
      </div>
    </div>
    <div class="d-flex mt-3">
      <button
        class="button-black ml-3"
        :disabled="numRows !== 1"
        :class="{ disable: numRows !== 1 }"
        @click="openModalMessage"
      >
        SEND MESSAGE
      </button>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <Datatable
        :headers="headers"
        :dataColumn="dataUser"
        :itemKey="itemKey"
        :totalPages="totalPages"
        :currentPage="currentPage"
        :show="true"
        @sendCurrentPage="getCurrentPage"
        @rowSelect="rowSelect"
      >
      </Datatable>
    </div>

    <modalAddData
      ref="ModalAddMessages"
      :title="'Add Message'"
      @updateData="acceptAddMessage"
      :disabledButton="message && message.length < 500"
    >
      <div class="tax-modal__content__form__formgroup">
        <label for="firstname" style="width: 20%">Message:</label>
        <div
          class="tax-modal__content__form__formgroup__right d-flex align-items-center"
        >
          <textarea
            maxlength="500"
            type="text"
            id="firstname"
            v-model="message"
          />
        </div>
      </div>
    </modalAddData>
  </div>
</template>
<script>
import Datatable from '@/components/datatable.vue';
import InputSearch from '@/components/inputSearch.vue';
import UserService from '@/services/user.service.js';
import ChatService from '@/services/chat.service.js';
import { capitalizeFirstLetter } from '@/utils/helper.js';
import modalAddData from '@/components/modalAddData.vue';
import { PATH } from '@/variables/path.js';
export default {
  name: 'Users',
  data() {
    return {
      PATH,
      listUsers: [],
      dataSearch: '',
      headers: [
        { text: 'USERNAME', value: 'username' },
        { text: 'EMAIL', value: 'email' },
        { text: 'FIRST NAME', value: 'first_name' },
        { text: 'LAST NAME', value: 'last_name' },
        { text: 'INCOME STATUS', value: 'income_status' },
        { text: 'DIPLOMA STATUS', value: 'diploma_status' },
        { text: 'ACTIVE', value: 'is_active' },
      ],
      itemKey: 'user_id',
      dataUser: [],
      incomeStatus: '',
      diplomaStatus: '',
      currentPage: 1,
      currentLimit: 10,
      totalPages: 0,
      listUserSelected: [],
      message: '',
      numRows: 0,
    };
  },
  components: {
    Datatable,
    InputSearch,
    modalAddData,
  },
  methods: {
    capitalizeFirstLetter,
    rowSelect(value) {
      this.numRows = value.length;
      this.listUserSelected = value;
    },
    getListUser(page, limit, search, incomeStatus, diplomaStatus) {
      const params = {
        page,
        limit,
        search,
        incomeStatus,
        diplomaStatus,
        onlyPending: true,
      };
      UserService.getUserList(params).then((res) => {
        this.totalPages = Math.ceil(res.count / this.currentLimit);
        res.results.map((result) => {
          result.income_status = this.capitalizeFirstLetter(
            result.income_status
          );
          result.diploma_status = this.capitalizeFirstLetter(
            result.diploma_status
          );
        });
        this.dataUser = res.results;
      });
    },
    getCurrentPage(value) {
      this.currentPage = value;
      this.getListUser(
        value,
        this.currentLimit,
        this.dataSearch,
        this.incomeStatus,
        this.diplomaStatus
      );
    },
    openModalMessage() {
      this.message = '';
      this.$refs.ModalAddMessages.openModal();
    },
    acceptAddMessage() {
      const data = {
        user_id: this.listUserSelected[0].user_id,
        message: this.message,
      };
      ChatService.createNewChannel(data).then(() => {
        this.$router.push(PATH.CHATS);
      });
    },
  },
  mounted() {
    this.getListUser(
      this.currentPage,
      this.currentLimit,
      this.dataSearch,
      this.incomeStatus,
      this.diplomaStatus
    );
  },
  watch: {
    dataSearch(value) {
      this.currentPage = 1;
      this.getListUser(
        this.currentPage,
        this.currentLimit,
        value,
        this.incomeStatus,
        this.diplomaStatus
      );
    },
    incomeStatus(value) {
      this.currentPage = 1;
      this.getListUser(
        this.currentPage,
        this.currentLimit,
        this.dataSearch,
        value,
        this.diplomaStatus
      );
    },
    diplomaStatus(value) {
      this.currentPage = 1;
      this.getListUser(
        this.currentPage,
        this.currentLimit,
        this.dataSearch,
        this.incomeStatus,
        value
      );
    },
  },
};
</script>
