<template>
  <div class="new-modal">
    <b-modal
      v-model="isOpen"
      centered
      hideFooter
      hideHeader
      no-close-on-esc
      no-close-on-backdrop
      class="position-relative"
    >
      <div class="tax-modal">
        <div class="tax-modal__header">
          <img
            class="tax-modal__header__logo"
            src="@/assets/icon/edit-icon.svg"
            alt="Edit icon"
          />
          <span class="tax-modal__header__title">{{ title }}</span>
        </div>
        <div class="tax-modal__content">
          <form class="tax-modal__content__form">
            <slot></slot>
          </form>
        </div>
        <div class="tax-modal__footer tax-modal__footer--left">
          <button
            :disabled="!disabledButton"
            class="button-primary"
            @click="editData"
          >
            OK
          </button>
          <button class="button-black" @click="closeModal">Cancel</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    title: {},
    disabledButton: {},
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit('closePopupData');
    },
    editData() {
      this.$emit('updateData');
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  &:disabled {
    opacity: 0.6;
  }
}
</style>
