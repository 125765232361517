import { api } from '@goldfishcode/wishful-thinking-api-sdk';
class UserServices {
  me() {
    return api.User.me();
  }
  getUserList(data) {
    const params = {
      page: data.page,
      limit: data.limit,
      search: data.search,
      income_status: data.incomeStatus,
      diploma_status: data.diplomaStatus,
      only_pending: data.onlyPending ? data.onlyPending : false,
    };
    return api.UserAdmin.list(params);
  }
  userDetail(user_id) {
    return api.UserAdmin.getUser(user_id);
  }
  userReport(data) {
    const params = {
      page: data.page,
      limit: data.limit,
      search: data.search,
    };
    return api.UserAdmin.listReport(params);
  }
  editStatus(id, data) {
    return api.UserAdmin.verifyUser(id, data);
  }
  deleteUser(data) {
    return api.UserAdmin.bulkDeleteUser({ id_list: data });
  }

  getFAQList(page, limit, search) {
    return api.UserAdmin.listFAQ({ page, limit, search });
  }

  deleteFAQ(id) {
    return api.UserAdmin.deleteFAQ(id);
  }

  updateFAQ(id, data) {
    return api.UserAdmin.updateFAQ(id, data);
  }

  createFAQ(data) {
    return api.UserAdmin.createFAQ(data);
  }

  getFAQDetail(id) {
    return api.UserAdmin.getFAQ(id);
  }

  getListReportReason(page, limit, search) {
    return api.UserAdmin.listReportReason({ page, limit, search });
  }

  getReportReasonDetail(reasonID) {
    return api.UserAdmin.getReportReason(reasonID);
  }

  createReportReason(data) {
    return api.UserAdmin.createReportReason(data);
  }

  updateReportReason(reasonID, data) {
    return api.UserAdmin.updateReportReason(reasonID, data);
  }

  deleteReportReason(reasonID) {
    return api.UserAdmin.deleteReportReason(reasonID);
  }

  updateUserSetting(adminNoti) {
    return api.UserAdmin.updateUserSetting({
      admin_chat_notification: adminNoti,
    });
  }

  deleteImages(id_list) {
    return api.UserAdmin.bulkDeleteUserImages(id_list);
  }
}
export default new UserServices();
