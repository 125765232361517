/** @format */

import Vue from 'vue';

function filter() {
  Vue.filter('firstLetter', function (value) {
    if (!value && value !== 0) {
      return '';
    }
    let newVal = value.split(' ');
    let firstLetter = '';
    if (newVal.length > 0) {
      newVal.forEach((element) => {
        firstLetter = firstLetter + element.charAt(0);
      });
    }
    return firstLetter;
  });

  Vue.filter('trimtext', function (value, index) {
    if (!value && value !== 0) {
      return '';
    }
    if (value.length > index) {
      value = value.substring(0, index) + ' ...';
    }
    return value;
  });

  Vue.filter('unit', function (value, name) {
    if (!value || value === 'undefined') {
      return value;
    }
    if (value > 1) {
      value = value + ' ' + name + 's';
    } else {
      value = value + ' ' + name;
    }
    return value;
  });
}

export default filter;
