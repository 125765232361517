<template>
  <div class="new-modal">
    <b-modal
      v-model="isOpen"
      centered
      hideFooter
      hideHeader
      no-close-on-esc
      no-close-on-backdrop
      class="position-relative"
    >
      <div class="tax-modal">
        <div class="tax-modal__header tax-modal__header--empty"></div>
        <div class="tax-modal__content tax-modal__content--warning">
          <img :src="icon" alt="Warning icon" />
          <p class="title-text">{{ title }}</p>
        </div>
        <div class="tax-modal__footer tax-modal__footer--centering">
          <button class="button-primary" @click="accept">{{ textYes }}</button>
          <button v-if="showCancel" class="button-black" @click="reject">
            {{ textNo }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    textYes: {
      default: 'Accept',
    },
    textNo: {
      default: 'Cancel',
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: require('@/assets/icon/warning-icon.svg'),
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
    accept() {
      this.$emit('accept');
    },
    reject() {
      this.$emit('reject');
      this.closeModal();
    },
  },
};
</script>
