<template>
  <div class="datatable">
    <v-app id="inspire">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="dataColumn"
        :single-select="false"
        :item-key="itemKey"
        :show-select="show"
        class="elevation-1"
      >
        <template v-slot:[`item.username`]="{ item }">
          <router-link :to="`${PATH.USER_DETAIL}/${item[itemKey]}`">{{
            item.username
          }}</router-link>
        </template>
        <template v-slot:[`item.owner`]="{ item }">
          <router-link
            :to="`${PATH.USER_DETAIL}/${item['owner_id']}`"
            v-if="!item.is_removed"
            >{{ item.owner }}</router-link
          >
          <span v-else>{{ item.owner }}</span>
        </template>
        <template v-slot:[`item.user`]="{ item }">
          <router-link
            :to="`${PATH.USER_DETAIL}/${item['user_id']}`"
            v-if="!item.is_removed"
            >{{ item.user }}</router-link
          >
          <span v-else>{{ item.user }}</span>
        </template>
        <template v-slot:[`item.user.name`]="{ item }">
          <router-link
            :class="item.user.is_removed"
            :to="`${PATH.USER_DETAIL}/${item.user.user_id}`"
            >{{ item.user.name }}</router-link
          >
        </template>

        <template v-slot:[`item.user_to.name`]="{ item }">
          <router-link :to="`${PATH.USER_DETAIL}/${item.user_to.user_id}`">{{
            item.user_to.name
          }}</router-link>
        </template>

        <template v-slot:[`item.list_id`]="{ item }">
          <div
            class="pointer view-images"
            @click="openModalConverstion(item.list_id)"
          >
            View
          </div>
        </template>
        <template v-slot:[`item.user_from.name`]="{ item }">
          <router-link :to="`${PATH.USER_DETAIL}/${item.user_from.user_id}`">{{
            item.user_from.name
          }}</router-link>
        </template>

        <template v-slot:[`item.url`]="{ item }">
          <a
            class="url-product"
            :href="`${item.url}`"
            target="_blank"
            :title="`${item.url}`"
            >{{ item.url | trimtext(20) }}</a
          >
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <div class="product-desc" v-html="item.description"></div>
        </template>

        <!--        <template v-slot:[`item.min_cost`]="{ item }">-->
        <!--          <div class="product-min">-->
        <!--            <UpdateField-->
        <!--              @save="save(item)"-->
        <!--              v-model="item.min_cost"-->
        <!--              :rangeCost="item.range_cost"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </template>-->

        <!--        <template v-slot:[`item.max_cost`]="{ item }">-->
        <!--          <div class="product-max">-->
        <!--            <UpdateField-->
        <!--              @save="save(item)"-->
        <!--              v-model="item.max_cost"-->
        <!--              :rangeCost="item.range_cost"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </template>-->

        <template v-slot:[`item.range_allowed_cost`]="{ item }">
          <div class="range_allowed_cost">
            <UpdateField @save="save($event)" :item="item" />
          </div>
        </template>

        <template v-slot:[`item.is_visible`]="{ item }">
          <div>
            <v-switch
              v-model="item.is_visible"
              color="success"
              hide-details
              @change="save(item)"
            ></v-switch>
          </div>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <div class="product-desc">{{ item.name }}</div>
        </template>
        <template v-slot:[`item.question`]="{ item }">
          <div class="product-desc">{{ item.question }}</div>
        </template>
        <template v-slot:[`item.answer`]="{ item }">
          <div class="product-desc">{{ item.answer }}</div>
        </template>

        <template v-slot:[`item.income_status`]="{ item }">
          <v-chip :color="getClassStyle('status', item.income_status)">
            {{ item.income_status }}
          </v-chip>
        </template>
        <template v-slot:[`item.diploma_status`]="{ item }">
          <v-chip :color="getClassStyle('status', item.diploma_status)">
            {{ item.diploma_status }}
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getClassStyle('status', item.status)">
            {{
              item.status === 'Confirm_start' ? 'Confirm Start' : item.status
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.is_active`]="{ item }">
          <div :class="getClassStyle('active', item.is_active)">
            {{ item.is_active }}
          </div>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ item.created | moment('MMM. D YYYY, hh:mm A') }}
        </template>
        <template v-slot:[`item.modified`]="{ item }">
          {{ item.modified | moment('MMM. D YYYY, hh:mm A') }}
        </template>
        <template v-slot:[`item.timeDate`]="{ item }">
          <div v-for="i in item.timeDate" :key="i">
            {{ i | moment('MMM. D YYYY, hh:mm A') }}
          </div>
        </template>
        <template v-slot:[`item.chooseTime`]="{ item }">
          {{ item.chooseTime | moment('MMM. D YYYY, hh:mm A') }}
        </template>
        <template v-slot:[`item.images`]="{ item }">
          <div
            v-if="item.images.length"
            class="pointer view-images"
            @click="openModalImage(item.images)"
          >
            View images
          </div>
        </template>
        <template v-slot:[`item.faqAction`]="{ item }">
          <div class="d-flex">
            <div @click="updateAction(item.id)">
              <CIcon class="pointer mr-2" name="cil-pencil" />
            </div>

            <div @click="deleteAction(item.id)">
              <CIcon class="pointer" name="cil-trash" />
            </div>
          </div>
        </template>
      </v-data-table>
    </v-app>

    <v-pagination
      v-model="page"
      class="my-4"
      :length="totalPages"
      :total-visible="6"
      @input="onPageChange"
    ></v-pagination>
    <ImageModal ref="img-modal" :imgList="imageList" />
    <ConversationModal ref="conversation-modal" />
  </div>
</template>
<script>
import { PATH } from '@/variables/path.js';
import UpdateField from '@/components/UpdateField';
import Vue from 'vue';

export default {
  name: 'datatable',
  components: {
    ImageModal: () => import('@/components/modals/show-images-modal.vue'),
    ConversationModal: () =>
      import('@/components/modals/show-conversation-modal.vue'),
    UpdateField,
  },
  props: {
    headers: {},
    dataColumn: {},
    itemKey: {},
    totalPages: {},
    currentPage: {},
    show: Boolean,
  },
  data() {
    return {
      selected: [],
      page: 1,
      PATH,
      imageList: [],
      idList: [],
    };
  },
  methods: {
    openModalImage(imageList) {
      const customList = [];
      imageList.map((item) => {
        customList.push({ url: item });
      });
      this.imageList = customList;
      this.$refs['img-modal'].openModal();
    },
    openModalConverstion(idList) {
      this.idList = idList;
      this.$refs['conversation-modal'].getMessage(idList);
    },
    getClassStyle(key, data) {
      let arrayStyleKey = [
        {
          key: 'status',
          data: 'Rejected',
          style: 'text-rejected',
        },
        {
          key: 'status',
          data: 'Cancelled',
          style: 'text-rejected',
        },
        {
          key: 'status',
          data: 'Deny',
          style: 'text-rejected',
        },
        {
          key: 'status',
          data: 'New',
          style: 'text-open',
        },
        {
          key: 'status',
          data: 'Start',
          style: 'text-approved',
        },
        {
          key: 'status',
          data: 'Confirm_start',
          style: 'text-approved',
        },
        {
          key: 'status',
          data: 'Open',
          style: 'text-open',
        },
        {
          key: 'status',
          data: 'Approved',
          style: 'text-approved',
        },
        {
          key: 'status',
          data: 'Accept',
          style: 'text-approved',
        },
        {
          key: 'status',
          data: 'Ready',
          style: 'text-approved',
        },
        {
          key: 'status',
          data: 'Finished',
          style: 'text-finished',
        },
        {
          key: 'status',
          data: 'Pending',
          style: 'text-pending',
        },
        {
          key: 'status',
          data: 'Request',
          style: 'text-request',
        },
        {
          key: 'active',
          data: true,
          style: 'text-active',
        },
        {
          key: 'active',
          data: false,
          style: 'text-inactive',
        },
      ];
      let indexfindElemnt = arrayStyleKey.findIndex((el) => {
        return el.key === key && el.data === data;
      });
      if (indexfindElemnt !== -1) {
        return arrayStyleKey[indexfindElemnt].style;
      }
    },
    onPageChange(value) {
      this.$emit('sendCurrentPage', value);
    },
    deleteAction(id) {
      this.$emit('deleteAction', id);
    },
    updateAction(id) {
      this.$emit('updateAction', id);
    },
    save(product) {
      const idx = this.dataColumn.findIndex((item) => item.id === product.id);

      if (idx !== -1) {
        Vue.set(this.dataColumn, idx, product);
        this.$emit('updateValue', product);
      }
    },
  },
  watch: {
    currentPage: {
      deep: true,
      immediate: true,
      handler(value) {
        this.page = value;
      },
    },
    selected: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit('rowSelect', value);
      },
    },
  },
};
</script>
