import { render, staticRenderFns } from "./modalAddData.vue?vue&type=template&id=2ac0d300&scoped=true&"
import script from "./modalAddData.vue?vue&type=script&lang=js&"
export * from "./modalAddData.vue?vue&type=script&lang=js&"
import style0 from "./modalAddData.vue?vue&type=style&index=0&id=2ac0d300&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ac0d300",
  null
  
)

export default component.exports