import { PATH } from '@/variables/path.js';
export function checkLoginMiddleware(to, from, next) {
  checkNavParent(to);
  if (sessionStorage.getItem('AUTH_SESSION_KEY')) {
    next();
  } else {
    if (to.meta.authentication) {
      next(PATH.LOGIN);
    } else {
      next();
    }
  }
}

export function checkNavParent(to) {
  const navList = [
    {
      parent: PATH.FAQS,
      children: [PATH.CREATE_FAQ],
    },
    {
      parent: PATH.REASON_REPORT_USER,
      children: [PATH.CREATE_REASON_REPORT],
    },
  ];
  const i = navList.filter((item) => {
    const indexof = item.children.indexOf(to.path);
    return indexof > -1;
  });
  addNavActive(i, to);
}

export function addNavActive(navItem, to) {
  setTimeout(() => {
    const nav = document.getElementsByClassName('c-sidebar-nav-link');
    for (var i = 0; i < nav.length; i++) {
      const href = nav[i].getAttribute('href');
      if (navItem.length) {
        if (href === navItem[0].parent) {
          nav[i].classList.add('c-active');
        } else {
          nav[i].classList.remove('c-active');
        }
      } else if (href !== to.path) {
        nav[i].classList.remove('c-active');
      }
    }
  }, 100);
}
