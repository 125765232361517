import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { PATH } from '@/variables/path.js';
// Chat
import Chat from '@/views/Chat/Chats.vue';

// Date
import FlashDate from '@/views/Date/FlashDate.vue';
import UserFlashDate from '@/views/Date/UserFlashDate.vue';

// Store
import OrderHistory from '@/views/Store/OrderHistory.vue';
import Products from '@/views/Store/Products.vue';

// Users
import Faqs from '@/views/Users/Faqs.vue';
import ReasonReportUser from '@/views/Users/reasonReport/ReasonReportUser.vue';
import CreateReasonReport from '@/views/Users/reasonReport/CreateReasonReport.vue';
import ReportedUser from '@/views/Users/ReportedUser.vue';
// import TermCondition from '@/views/Users/TermCondition.vue';
import Users from '@/views/Users/Users.vue';
import VerifyIncome from '@/views/Users/VerifyIncome';
import CreateFAQ from '@/views/Users/Create-FAQ';

// Login
import Login from '@/views/Login/Login.vue';

// User Details
import UserDetail from '@/views/Users/UserDetail.vue';

// Dashboard
// import Dashboard from '@/views/Dashboard/Dashboard.vue';

// Check Login Middleware
import { checkLoginMiddleware } from '@/router/middleware.js';

Vue.use(VueRouter);

const routes = [
  {
    path: PATH.LOGIN,
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: PATH.CHATS,
    meta: {
      authentication: true,
    },
    children: [
      // {
      //   name: 'Dashboard',
      //   component: Dashboard,
      //   path: PATH.DASHBOARD,
      //   meta: {
      //     authentication: true,
      //   },
      // },
      {
        name: 'Chats',
        path: PATH.CHATS,
        component: Chat,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'Flash Date',
        path: PATH.FLASH_DATES,
        component: FlashDate,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'User Flash Date',
        path: PATH.USER_FLASH_DATES,
        component: UserFlashDate,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'Order history',
        path: PATH.ORDER_HISTORY,
        component: OrderHistory,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'Products',
        path: PATH.PRODUCTS,
        component: Products,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'FAQs',
        path: PATH.FAQS,
        component: Faqs,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'ReasonReporUser',
        path: PATH.REASON_REPORT_USER,
        component: ReasonReportUser,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'CreateReasonReport',
        path: PATH.CREATE_REASON_REPORT,
        component: CreateReasonReport,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'Reported Users',
        path: PATH.REPORTED_USERS,
        component: ReportedUser,
        meta: {
          authentication: true,
        },
      },
      // {
      //   name: 'Term Conditions',
      //   path: PATH.TERM_CONDITIONS,
      //   component: TermCondition,
      //   meta: {
      //     authentication: true,
      //   },
      // },
      {
        name: 'User',
        path: PATH.USERS,
        component: Users,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'Verify Income',
        path: PATH.VERIFY_INCOME,
        component: VerifyIncome,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'User Detail',
        path: `${PATH.USER_DETAIL}/:user_id`,
        component: UserDetail,
        meta: {
          authentication: true,
        },
      },
      {
        name: 'CreateFaq',
        path: PATH.CREATE_FAQ,
        component: CreateFAQ,
        meta: {
          authentication: true,
        },
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(checkLoginMiddleware);

export default router;
