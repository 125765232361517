import { ApiClient, api } from '@goldfishcode/wishful-thinking-api-sdk';

class SessionStorage {
  // store key/value pair
  async set(key, value) {
    sessionStorage.setItem(key, value);
  }

  // get value of
  async get(key) {
    const value = sessionStorage.getItem(key);
    return value || '';
  }

  // delete key
  async remove(key) {
    sessionStorage.removeItem(key);
  }
}

class APICONFIG {
  static initConfig() {
    const sessionStorage = new SessionStorage();
    const config = {
      baseUrl: process.env.VUE_APP_API_URL,
      AUTH_SESSION_KEY: 'AUTH_SESSION_KEY',
      session: sessionStorage,
      socketUrl: process.env.VUE_APP_SOCKET_URL,
    };
    ApiClient.setApiConfig(config);
    api.Auth.refreshToken();
  }
}

export default APICONFIG;
