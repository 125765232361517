export const Toastmixin = {
  methods: {
    showToast(messages, title, type) {
      // const hiddenTime = 3000;
      // this.$bvToast.toast(title, {
      //   title: messages,
      //   autoHideDelay: hiddenTime + 100,
      //   visible: true,
      //   variant: type,
      //   appendToast: true,
      //   toastClass: ['showing', 'check-showing'],
      // });

      this.$toast.success(title, {
        // override the global option
        position: 'top',
        type: ['error', 'danger'].includes(type.toLowerCase())
          ? 'error'
          : type.toLowerCase(),
      });

      // setTimeout(() => {
      //   const el = document.getElementsByClassName('check-showing');
      //   for (let i = 0; i < el.length; i++) {
      //     el[i].classList.add('hide-toast');
      //   }
      // }, hiddenTime);
    },
  },
};
