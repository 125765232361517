import { api } from '@goldfishcode/wishful-thinking-api-sdk';
class ProductService {
  getProductList(page, limit, search) {
    const params = {
      page,
      limit,
      search,
    };
    return api.StoreAdmin.listProduct(params);
  }
  getOrderHistory(page, limit, search) {
    const params = {
      page,
      limit,
      search,
    };
    return api.StoreAdmin.listHistory(params);
  }

  updateProduct(item) {
    const params = {
      product_id: item.product_id,
      is_visible: item.is_visible,
      name: item.name,
      description: item.description,
      url: item.url,
      max_cost: item.max_cost,
      min_cost: item.min_cost,
      range_cost: item.range_cost,
    };

    return api.StoreAdmin.updateProduct(item.id, params);
  }
}
export default new ProductService();
