<template>
  <div class="input-search d-flex justify-content-between align-items-center">
    <input placeholder="Search..." :value="dataSearch" @input="getDataInput" />
    <img src="@/assets/icon/search.svg" />
  </div>
</template>
<script>
var _ = require('lodash');
export default {
  name: 'InputSearch',
  data() {
    return {
      dataSearch: '',
    };
  },
  methods: {
    getDataInput: _.debounce(function (e) {
      this.dataSearch = e.target.value;
      this.$emit('input', this.dataSearch);
    }, 800),
  },
};
</script>
