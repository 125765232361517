export function formatTime(seconds) {
  if (seconds) {
    return new Date(seconds * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
  }
  return seconds;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function validateNameCreate(string) {
  return string.trim();
}

export const limit = 10;
