import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import filter from './utils/filter.js';
import VueToast from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueMoment, {
  moment,
});

Vue.use(VueToast, {
  // One of the options
  position: 'top',
});

// config sdk
import APICONFIG from '@/config-sdk.js';
APICONFIG.initConfig();

import { init } from '@/services/bus.service';
init();

// styles
import './assets/styles/main.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// bootstrap / bootstrap-vue
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

// axios
import axios from 'axios';
Vue.use(axios);

// envent bus
import VueEventBus from 'vue-event-bus';
Vue.use(VueEventBus);

// vue-side-bar
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
// vuetify
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
Vue.use(Vuetify);

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-free/css/all.css';

// core UI
import CoreuiVue from '@coreui/vue';
import * as icons from '@coreui/icons';
Vue.use(CoreuiVue);

// inteceptor
import intercepterConfig from '@/interceptors.js';
Vue.use(intercepterConfig);

Vue.use(filter);
library.add(faUserSecret);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// vue advanced chat
import 'vue-advanced-chat/dist/vue-advanced-chat.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  icons,
  vuetify: new Vuetify(),
  render: (h) => h(App),
}).$mount('#app');
