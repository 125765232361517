import Vue from 'vue';
import Vuex from 'vuex';
import reponsive from './modules/responsiveWebsite';
import auth from './modules/auth';
import user from './modules/user';

Vue.use(Vuex);

const debug = true;

export default new Vuex.Store({
  modules: {
    auth,
    reponsive,
    user,
  },
  strict: debug,
});
