import UserService from '@/services/user.service.js';
export default {
  namespaced: true,
  state: {
    userId: '',
  },
  actions: {
    getUserInfo({ commit }) {
      UserService.me().then((res) => {
        commit('SET_USER_ID', res.user_id);
      });
    },
  },
  mutations: {
    SET_USER_ID(state, data) {
      state.userId = data;
    },
  },
};
