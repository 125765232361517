<template>
  <div class="login__wrapper">
    <div class="login">
      <div class="login__header">
        <p class="login__header__text">Welcome to</p>
        <h1 class="login__header__project-name text-center">
          WISHFUL THINKING
        </h1>
      </div>
      <div class="login__form">
        <form>
          <p class="login__form__title">Login</p>
          <div class="form-group">
            <label for="user" class="login__form__label">Username</label>
            <validation-provider rules="required">
              <div slot-scope="{ errors }">
                <input
                  type="user"
                  required
                  id="user"
                  class="login__form__input"
                  v-model="user"
                />
                <span class="error-message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="form-group">
            <label for="password" class="login__form__label">Password</label>
            <validation-provider rules="required">
              <div slot-scope="{ errors }">
                <input
                  type="password"
                  required
                  id="password"
                  class="login__form__input"
                  v-model="password"
                />
                <span class="error-message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="login__form__submit">
            <button
              @click.prevent="login($event)"
              type="submit"
              class="login__form__submit__button"
            >
              LOGIN
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { PATH } from '@/variables/path.js';
import AuthServices from '@/services/auth.service.js';
import { extend } from 'vee-validate';
import { ValidationProvider } from 'vee-validate';
import { Toastmixin } from '@/mixins/toastMinxin';
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    };
  },
  message: 'This field is required',
  computesRequired: true,
});
export default {
  name: 'login',
  mixins: [Toastmixin],
  components: {
    ValidationProvider,
  },
  data: function () {
    return {
      user: '',
      password: '',
    };
  },
  methods: {
    login() {
      AuthServices.login(this.user, this.password)
        .then((res) => {
          if (
            res.roles.length &&
            (res.roles.includes('SUPERUSER') || res.roles.includes('STAFF'))
          ) {
            this.$store.commit('auth/setCurrentRole', res.roles);
            this.$router.push(PATH.HOME);
          } else {
            this.showToast(
              'Message',
              "You don't have permission to access this page",
              'danger'
            );
          }
        })
        .catch((error) => {
          this.showToast(
            'Message',
            error && error.message
              ? !this.user
                ? 'Please input Username'
                : !this.password
                ? 'Please input Password'
                : error.message
              : 'Invalid Username or Password',
            'danger'
          );
        });
    },
  },
};
</script>
