import { api } from '@goldfishcode/wishful-thinking-api-sdk';

class FlashDateService {
  getFlashDateList(data) {
    const params = {
      page: data.page,
      limit: data.limit,
      search: data.search,
      status: data.status,
      is_active: data.isActive,
    };
    return api.FlashDateAdmin.listFlashDate(params);
  }
  getListUserFlashDate(data) {
    const params = {
      page: data.page,
      limit: data.limit,
      search: data.search,
      status: data.status,
    };
    return api.FlashDateAdmin.listUserFlashDate(params);
  }
}
export default new FlashDateService();
