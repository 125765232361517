<template>
  <div class="c-app">
    <SideBar />
    <CWrapper>
      <Header />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBar from '@/components/sidebar.vue';
import Header from '@/components/header.vue';
export default {
  name: 'Home',
  components: {
    SideBar,
    Header,
  },
};
</script>
