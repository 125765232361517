import { api } from '@goldfishcode/wishful-thinking-api-sdk';
class ChatService {
  getListChat(data) {
    const params = {
      page: data.page,
      limit: data.limit,
      search: data.search,
    };
    return api.Chat.Channel.listChannel(params);
  }
  getMessagesEachChannel(data) {
    const params = {
      page: data.page,
      limit: data.limit,
    };
    return api.Chat.Message.getMessagesInChannel(data.idChannel, params);
  }
  sendMessage(data) {
    const params = {
      message: {
        type: 'TEXT',
        content: data.message,
      },
      channel_id: data.channelId,
    };
    return api.Chat.Message.sendMessage(params);
  }
  createNewChannel(data) {
    const params = {
      message: {
        type: 'TEXT',
        content: data.message,
      },
      user_id: data.user_id,
    };
    return api.Chat.Channel.createChannel(params);
  }
  markReadChannel(data) {
    const params = {
      channel_id: data.channelId,
    };
    return api.Chat.Message.markReadChannel(params);
  }
  searchUser(page, limit, search, include_channel = true) {
    return api.UserAdmin.list({ page, limit, search, include_channel });
  }

  onMessage(callBack) {
    const listener = api.Chat.SocketListener.instance();
    listener.onMessage((msg) => callBack(msg));
  }

  onNotificationRefresh(callBack) {
    const listener = api.Chat.SocketListener.instance();
    listener.onNotificationRefresh((msg) => callBack(msg));
  }
  conversationReportUser(params) {
    return api.Chat.ChatAdmin.getUserMessages(params);
  }
}
const chatService = new ChatService();
export default chatService;
