<template>
  <div class="my-spinner" v-bind:class="{ 'spinner-active': isSpinner }">
    <b-spinner class="spinner-item" label="Large Spinner"></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'spinner',
  data: () => ({
    isSpinner: false,
  }),
  mounted() {
    var that = this;
    this.$bus.$on('spinner', function (e) {
      that.isSpinner = e;
    });
  },
};
</script>
