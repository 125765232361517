<template>
  <div id="app">
    <spinner></spinner>
    <router-view />
  </div>
</template>
<script>
import spinner from '@/common/spinner.vue';
export default {
  name: 'App',
  components: {
    spinner,
  },
};
</script>
